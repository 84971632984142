import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Form,
    Input,
    Checkbox,
    Select,
    notification,
    DatePicker,
    TimePicker,
    Tooltip,
    Icon,
    Modal,
    Radio,
    Row,
    Col,
    InputNumber,
    Button,
    Collapse
} from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState";
import noimage from "../../../images/noimage.png";
import DrawerPesquisar from "./DrawerPesquisar";

const { Option } = Select;
const { Panel } = Collapse;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormVideoConf extends Component {
    static contextType = GlobalContext;
    state = {
        btnIniciar: false,
        assunto: "",
        dia: null,
        hora: null,
        inicio: null,
        fim: null,
        duracao: moment("01:00", "HH:mm"),
        gravarSessao: false,
        habilitarChat: false,
        totalContratado: "",
        recorrente: false,
        recorrenciaIntervalo: 1,
        recorrencia: "SEMANA",
        recorrenciaFim: null,
        //EXTERNA
        meetExterna: false,
        meetExternaConvite: "",
        //LOADING
        iconLoading: false,
        //NOTIFICAR
        notificar: false,
        //PERMISSOES
        permissaoDescarregar: 1,
        soGestoresVerDescarregar: false,
        //PARTICIANTES
        todos: false,
        participantes: [],
        //DRAWER PESQUISAR PARTICIPATES,
        visibleDrawerPesqusiar: false,
        //MODAL PARTICIPANTES DUPLICADOS
        iniciar: false,
        visibleParticipantes: false,
        loadingLista: false,
        participantesDuplicados: [],
        manha: false,
        tarde: false,
        manhaEtarde: true,
        sumarios: [{ key: 1, conteudo: { manha: "", tarde: "" } }],
        equipamentos: [{ key: 1, conteudo: { manha: "", tarde: "" } }],
        sumariosHora: [
            {
                key: 1,
                conteudo: {
                    manha: "",
                    tarde: "",
                    manhaInicio: null,
                    manhaFim: null,
                    tardeInicio: null,
                    tardeFim: null
                }
            }
        ]
    };

    handlerChangeCheckboxManha = event => {
        this.props.form.resetFields();
        this.setState({
            manha: event.target.checked,
            tarde: false,
            manhaEtarde: false
        });
    };

    handlerChangeCheckboxPorTarde = event => {
        this.props.form.resetFields();
        this.setState({
            tarde: event.target.checked,
            manha: false,
            manhaEtarde: false
        });
    };

    handlerChangeCheckboxPorTodos = event => {
        this.props.form.resetFields();
        this.setState({
            manhaEtarde: event.target.checked,
            manha: false,
            tarde: false
        });
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeRecorrenciaIntervalo = value => {
        this.setState({
            recorrenciaIntervalo: value
        });
    };

    handlerChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handlerChangeRadioGeral = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    addPanelSumarios = () => {
        const { sumarios } = this.state;
        const newKey = sumarios.length + 1;
        this.setState({
            sumarios: [...sumarios, { key: newKey, conteudo: { manha: "", tarde: "" } }]
        });

        const jsonString = JSON.stringify(sumarios);

        var item = new FormData();

        item.append("data", jsonString);

        console.log(item);
    };

    addPanelEquipamentos = () => {
        const { equipamentos } = this.state;
        const newKey = equipamentos.length + 1;
        this.setState({
            equipamentos: [...equipamentos, { key: newKey, conteudo: { manha: "", tarde: "" } }]
        });

        const jsonString = JSON.stringify(equipamentos);

        var item = new FormData();

        item.append("data", jsonString);

        console.log(item);
    };

    addPanelSumariosHora = () => {
        const { sumariosHora } = this.state;
        const newKey = sumariosHora.length + 1;
        this.setState({
            sumariosHora: [
                ...sumariosHora,
                {
                    key: newKey,
                    conteudo: {
                        manha: "",
                        tarde: "",
                        manhaInicio: null,
                        manhaFim: null,
                        tardeInicio: null,
                        tardeFim: null
                    }
                }
            ]
        });

        const jsonString = JSON.stringify(sumariosHora);

        var item = new FormData();

        item.append("data", jsonString);

        console.log(item);
    };

    handleInputChangeSumarios = (e, panelKey, field) => {
        const { sumarios } = this.state;
        const updatedPanels = sumarios.map(panel => {
            if (panel.key === panelKey) {
                const updatedContent = { ...panel.conteudo, [field]: e.target.value };
                return {
                    ...panel,
                    conteudo: updatedContent
                };
            }
            return panel;
        });
        this.setState({ sumarios: updatedPanels });
    };

    handleInputChangeEquipamentos = (e, panelKey, field) => {
        const { equipamentos } = this.state;
        const updatedPanels = equipamentos.map(panel => {
            if (panel.key === panelKey) {
                const updatedContent = { ...panel.conteudo, [field]: e.target.value };
                return {
                    ...panel,
                    conteudo: updatedContent
                };
            }
            return panel;
        });
        this.setState({ equipamentos: updatedPanels });
    };

    handleInputChangeSumariosHora = (e, panelKey, field) => {
        const { sumariosHora } = this.state;
        const updatedPanels = sumariosHora.map(panel => {
            if (panel.key === panelKey) {
                const updatedContent = { ...panel.conteudo, [field]: e.target.value };
                return {
                    ...panel,
                    conteudo: updatedContent
                };
            }
            return panel;
        });
        this.setState({ sumariosHora: updatedPanels });
    };

    handleTimeChangeSumariosHora = (time, timeString, panelKey, field) => {
        const { sumariosHora } = this.state;
        const updatedPanels = sumariosHora.map(panel => {
            if (panel.key === panelKey) {
                return {
                    ...panel,
                    conteudo: { ...panel.conteudo, [field]: timeString }
                };
            }
            return panel;
        });
        this.setState({ sumariosHora: updatedPanels });
    };

    validarParticipantesDuplicadosCriar = iniciar => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.todos || this.state.participantes.length > 0)) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData();
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("todos", this.state.todos);

                axios({
                    method: "post",
                    url: "/api/gerir-videoconferencia/validar-participantes-duplicados",
                    data: item
                })
                    .then(response => {
                        if (response.data.length) {
                            this.setState({
                                iniciar,
                                visibleParticipantes: true,
                                participantesDuplicados: response.data
                            });
                        } else this.criar(iniciar);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel validar");
                        this.setState({
                            iconLoading: false,
                            visibleParticipantes: false,
                            loadingLista: false
                        });
                    });
            } else {
                if (!this.state.todos && this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
            }
        });
    };

    validarParticipantesDuplicadosAlterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.todos || this.state.participantes.length > 0)) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData();
                item.append("id", this.props.videoConfId);
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("todos", this.state.todos);

                axios({
                    method: "post",
                    url: "/api/gerir-videoconferencia/validar-participantes-duplicados",
                    data: item
                })
                    .then(response => {
                        if (response.data.length) {
                            this.setState({
                                loadingLista: false,
                                visibleParticipantes: true,
                                participantesDuplicados: response.data
                            });
                        } else this.alterar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel validar");
                        this.setState({
                            iconLoading: false
                        });
                    });
            } else {
                if (!this.state.todos && this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
            }
        });
    };

    criar = iniciar => {
        //this.props.form.validateFieldsAndScroll((err, values) => {
        //    if (!err && (this.state.todos || this.state.participantes.length > 0)) {
        if (iniciar) this.props.prepararMeet(true);

        this.setState({
            visibleParticipantes: false
        });

        var item = new FormData();
        item.append("cursoID", localStorage.getItem("codigo_curso"));
        item.append("assunto", this.state.assunto);
        item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
        item.append("hora", this.state.hora.format("HH:mm"));
        item.append("inicio", this.state.inicio.format("HH:mm"));
        item.append("fim", this.state.fim.format("HH:mm"));
        item.append("duracao", this.state.duracao.format("HH:mm"));
        item.append("gravarSessao", this.state.gravarSessao);
        item.append("habilitarChat", this.state.habilitarChat);
        item.append("notificar", this.state.notificar);
        item.append("soGestoresVerDescarregar", this.state.soGestoresVerDescarregar);
        item.append("permissaoDescarregar", this.state.permissaoDescarregar);
        item.append("participantes", JSON.stringify(this.state.participantes));
        item.append("todos", this.state.todos);
        item.append("iniciar", iniciar);
        if (this.state.meetExterna) {
            item.append("meetExterna", this.state.meetExterna);
            item.append("meetExternaConvite", this.state.meetExternaConvite);
        }
        if (this.state.recorrente) {
            item.append("recorrente", this.state.recorrente);
            item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
            item.append("recorrencia", this.state.recorrencia);
            item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
        }

        axios({
            method: "post",
            url: !this.state.recorrente ? "/api/gerir-videoconferencia/criar-nova" : "/api/gerir-videoconferencia/criar-nova-recorrencia",
            data: item
        })
            .then(response => {
                if (!this.state.recorrente) {
                    if (iniciar) {
                        if (this.state.meetExterna)
                            this.props.IniciarMeet(this.state.meetExternaLink, this.state.meetExterna, response.data.split(";")[1]);
                        else
                            this.props.IniciarMeet(
                                `/gerir-curso/${response.data
                                    .split(";")[0]
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/vconf/${response.data.split(";")[1]}/player-videoconferencia`,
                                false,
                                response.data.split(";")[1]
                            );
                    } else openNotificationWithIcon("success", "Sucesso", "Criada nova Stepmeet");
                } else {
                    if (response.data.totalFalhas > 0)
                        openNotificationWithIcon(
                            "success",
                            "Sucesso",
                            `Criadas ${response.data.totalPrevisto - response.data.totalFalhas} Stepmeets das ${
                                response.data.totalPrevisto
                            } pretendidas`
                        );
                    else openNotificationWithIcon("success", "Sucesso", `Criadas ${response.data.totalPrevisto} Stepmeets`);
                }

                this.setState({
                    iconLoading: false
                });
                this.context.atualizarEventosHoje();
                this.props.atualizarListagem();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.props.prepararMeet(false);
                this.setState({
                    iconLoading: false
                });
            });
        //}
        //else {
        //    if (!this.state.todos && this.state.participantes.length === 0)
        //        openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
        //}
        //})
    };

    alterar = () => {
        //this.props.form.validateFieldsAndScroll((err, values) => {
        //    if (!err && (this.state.todos || this.state.participantes.length > 0)) {
        //        this.setState({
        //            iconLoading: true
        //        });

        this.setState({
            visibleParticipantes: false
        });

        var item = new FormData();
        item.append("id", this.props.videoConfId);
        item.append("cursoID", localStorage.getItem("codigo_curso"));
        item.append("assunto", this.state.assunto);
        item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
        item.append("hora", this.state.hora.format("HH:mm"));
        item.append("duracao", this.state.duracao.format("HH:mm"));
        item.append("gravarSessao", this.state.gravarSessao);
        item.append("habilitarChat", this.state.habilitarChat);
        item.append("notificar", this.state.notificar);
        item.append("soGestoresVerDescarregar", this.state.soGestoresVerDescarregar);
        item.append("permissaoDescarregar", this.state.permissaoDescarregar);
        item.append("participantes", JSON.stringify(this.state.participantes));
        item.append("todos", this.state.todos);
        if (this.state.meetExterna) {
            item.append("meetExternaConvite", this.state.meetExternaConvite);
        }

        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/alterar",
            data: item
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Stepmeet alterada");
                this.setState({
                    iconLoading: false
                });
                this.context.atualizarEventosHoje();
                this.props.atualizarListagem();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({
                    iconLoading: false
                });
            });
        //    }
        //    else {
        //        if (!this.state.todos && this.state.participantes.length === 0)
        //            openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
        //    }
        //});
    };

    guardar = () => {
        this.setState({
            visibleParticipantes: false
        });

        var item = new FormData();

        const JSONString = JSON.stringify(this.state.equipamentos);

        item.append("videoConfId", this.props.videoConfId);
        item.append("cursoId", localStorage.getItem("codigo_curso"));
        item.append("equipamentos", JSONString);

        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/guardar-equipamento",
            data: item
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Stepmeet alterada");
                this.setState({
                    iconLoading: false
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({
                    iconLoading: false
                });
            });
    };

    cancelar = () => {
        this.setState({
            visibleParticipantes: false,
            iconLoading: false
        });
    };

    carregarDetalhe = id => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/detalhe-ficha",
            params: {
                id
            }
        })
            .then(response => {
                console.log("AADDDDDDDDD: ", response.data.equipamentos);
                this.setState(
                    {
                        assunto: response.data.assunto,
                        dia: moment(response.data.meetingDate),
                        hora: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                        inicio: response.data.inicio ? moment(response.data.inicio, "HH:mm") : null,
                        fim: response.data.fim ? moment(response.data.fim, "HH:mm") : null,
                        duracao: moment(response.data.duracao, "HH:mm"),
                        meetExterna: response.data.meetExterna,
                        meetExternaConvite: response.data.meetExternaConvite,
                        gravarSessao: response.data.gravarSessao,
                        habilitarChat: response.data.habilitarChat,
                        soGestoresVerDescarregar: response.data.soGestoresVerDescarregar,
                        permissaoDescarregar: response.data.permissaoDescarregar,
                        todos: response.data.todos,
                        participantes: response.data.participantes,
                        equipamentos:
                            response.data.equipamentos && response.data.equipamentos.length > 0
                                ? response.data.equipamentos
                                : this.state.equipamentos
                    },
                    () => this.carregarTotalContratado()
                );
            })
            .catch(() => {});
    };

    carregarTotalContratado = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-contratado",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalContratado: response.data
                });
            })
            .catch(() => {});
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.videoConfId) {
                this.carregarDetalhe(this.props.videoConfId);
            } else {
                const inicio = moment();
                const minutos = 15 - (inicio.minute() % 15);

                const hora = moment(inicio).add(minutos, "minutes");

                this.carregarTotalContratado();
                this.setState(
                    {
                        assunto: "",
                        dia: moment(),
                        hora: hora,
                        duracao: moment("01:00", "HH:mm"),
                        gravarSessao: false,
                        habilitarChat: true,
                        recorrente: false,
                        recorrenciaIntervalo: 1,
                        recorrencia: "SEMANA",
                        recorrenciaFim: null,
                        //EXTERNA
                        meetExterna: false,
                        meetExternaConvite: "",
                        //NOTIFICAR
                        notificar: false,
                        //PERMISSOES
                        soGestoresVerDescarregar: false,
                        //PARTICIANTES
                        todos: true,
                        participantes: []
                    },
                    () => this.validarVerBotao()
                );
            }
        }
    };

    validarVerBotao = () => {
        var dataHora = `${moment(this.state.dia).format("MM/DD/YYYY")} ${moment(this.state.hora).format("HH:mm")}`;
        const agora = moment();
        const inicio = moment(dataHora);

        const diff = inicio.diff(agora);

        const diffDuration = moment.duration(diff);

        this.setState({
            btnIniciar: Math.round(diffDuration.asHours()) <= 0 && Math.round(diffDuration.asMinutes()) <= 15
        });
    };

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            btnIniciar,
            assunto,
            dia,
            hora,
            inicio,
            fim,
            duracao,
            gravarSessao,
            habilitarChat,
            totalContratado,
            recorrente,
            recorrenciaIntervalo,
            recorrencia,
            recorrenciaFim,
            //EXTERNA
            meetExterna,
            meetExternaConvite,
            //LOADING
            iconLoading,
            //NOTIFICAR
            notificar,
            //PERMISSOES
            soGestoresVerDescarregar,
            permissaoDescarregar,
            //PARTICIANTES
            todos,
            participantes,
            //DRAWER PESQUISAR PARTICIPATES,
            visibleDrawerPesqusiar,
            //MODAL PARTICIPANTES DUPLICADOS
            iniciar,
            visibleParticipantes,
            loadingLista,
            participantesDuplicados,
            sumarios,
            sumariosHora,
            equipamentos,
            manha,
            tarde,
            manhaEtarde
        } = this.state;

        const { videoConfId } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias drawer-stepmeets"
                    title={videoConfId ? "Alterar Stepmeet" : "Adicionar nova Stepmeet"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-add-curso" layout="horizontal">
                            <Form.Item>
                                <Checkbox name="presenca" checked={manha} onChange={this.handlerChangeCheckboxManha}>
                                    Manhã
                                </Checkbox>
                                <Checkbox name="falta" checked={tarde} onChange={this.handlerChangeCheckboxPorTarde}>
                                    Tarde
                                </Checkbox>
                                <Checkbox name="falta" checked={manhaEtarde} onChange={this.handlerChangeCheckboxPorTodos}>
                                    Todos
                                </Checkbox>
                            </Form.Item>

                            <Collapse defaultActiveKey={["1"]} className="collapse-campos">
                                <Panel header="Coordenadores / Instrutores" key="1">
                                    <Form.Item label="Dia">
                                        {getFieldDecorator("dia", {
                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                            initialValue: dia
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dia"
                                                onChange={dia => this.setState({ dia }, () => this.validarVerBotao())}
                                            />
                                        )}
                                    </Form.Item>

                                    {(manha || manhaEtarde) && (
                                        <>
                                            <Form.Item label="Inicio Manhã">
                                                {getFieldDecorator("inicio", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: inicio
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="inicio"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={inicio => this.setState({ inicio }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Fim Manhã">
                                                {getFieldDecorator("fim", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: fim
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="fim"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={fim => this.setState({ fim }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>
                                        </>
                                    )}

                                    {(tarde || manhaEtarde) && (
                                        <>
                                            <Form.Item label="Inicio Tarde">
                                                {getFieldDecorator("inicio", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: inicio
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="inicio"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={inicio => this.setState({ inicio }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Fim Tarde">
                                                {getFieldDecorator("fim", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: fim
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="fim"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={fim => this.setState({ fim }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>
                                        </>
                                    )}
                                </Panel>

                                <Panel header="Datas" key="2">
                                    {(manha || manhaEtarde) && (
                                        <>
                                            <Form.Item label="Teórica Manhã">
                                                {getFieldDecorator("inicio", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: inicio
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="inicio"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={inicio => this.setState({ inicio }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Prática Simulada Manhã">
                                                {getFieldDecorator("inicio", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: inicio
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="inicio"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={inicio => this.setState({ inicio }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Prática Contexto Trabalho Manhã">
                                                {getFieldDecorator("inicio", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: inicio
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="inicio"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={inicio => this.setState({ inicio }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>
                                        </>
                                    )}

                                    {(tarde || manhaEtarde) && (
                                        <>
                                            <Form.Item label="Teórica Tarde" visible={tarde}>
                                                {getFieldDecorator("fim", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: fim
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="fim"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={fim => this.setState({ fim }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Prática Simulada Tarde">
                                                {getFieldDecorator("fim", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: fim
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="fim"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={fim => this.setState({ fim }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Prática Contexto Trabalho Tarde" visible={tarde}>
                                                {getFieldDecorator("fim", {
                                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                                    initialValue: fim
                                                })(
                                                    <TimePicker
                                                        className="input-25"
                                                        name="fim"
                                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                                        format="HH:mm"
                                                        onChange={fim => this.setState({ fim }, () => this.validarVerBotao())}
                                                        placeholder="Selecionar"
                                                        suffixIcon={false}
                                                        clearIcon={false}
                                                        minuteStep={15}
                                                    />
                                                )}
                                            </Form.Item>
                                        </>
                                    )}
                                </Panel>

                                <Panel header="Configurações" key="3">
                                    {sumariosHora.map(panel => (
                                        <div key={panel.key} style={{ marginBottom: 16 }}>
                                            {(manha || manhaEtarde) && (
                                                <>
                                                    <Form.Item label={`Horário Manhã Início ${panel.key}`}>
                                                        <TimePicker
                                                            placeholder="Início"
                                                            value={
                                                                panel.conteudo.manhaInicio
                                                                    ? moment(panel.conteudo.manhaInicio, "HH:mm")
                                                                    : null
                                                            }
                                                            onChange={(time, timeString) =>
                                                                this.handleTimeChangeSumariosHora(
                                                                    time,
                                                                    timeString,
                                                                    panel.key,
                                                                    "manhaInicio"
                                                                )
                                                            }
                                                            format="HH:mm"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label={`Horário Manhã Fim ${panel.key}`}>
                                                        <TimePicker
                                                            placeholder="Fim"
                                                            value={
                                                                panel.conteudo.manhaFim ? moment(panel.conteudo.manhaFim, "HH:mm") : null
                                                            }
                                                            onChange={(time, timeString) =>
                                                                this.handleTimeChangeSumariosHora(time, timeString, panel.key, "manhaFim")
                                                            }
                                                            format="HH:mm"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label={`Sumário Manhã ${panel.key}`}
                                                        name={`assuntoManha_${panel.key}`}
                                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                                    >
                                                        <Input
                                                            placeholder="Digite o assunto da manhã"
                                                            value={panel.conteudo.manha}
                                                            onChange={e => this.handleInputChangeSumariosHora(e, panel.key, "manha")}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )}

                                            {(tarde || manhaEtarde) && (
                                                <>
                                                    <Form.Item label={`Horário Tarde Início ${panel.key}`}>
                                                        <TimePicker
                                                            placeholder="Início"
                                                            value={
                                                                panel.conteudo.tardeInicio
                                                                    ? moment(panel.conteudo.tardeInicio, "HH:mm")
                                                                    : null
                                                            }
                                                            onChange={(time, timeString) =>
                                                                this.handleTimeChangeSumariosHora(
                                                                    time,
                                                                    timeString,
                                                                    panel.key,
                                                                    "tardeInicio"
                                                                )
                                                            }
                                                            format="HH:mm"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label={`Horário Tarde Fim ${panel.key}`}>
                                                        <TimePicker
                                                            placeholder="Fim"
                                                            value={
                                                                panel.conteudo.tardeFim ? moment(panel.conteudo.tardeFim, "HH:mm") : null
                                                            }
                                                            onChange={(time, timeString) =>
                                                                this.handleTimeChangeSumariosHora(time, timeString, panel.key, "tardeFim")
                                                            }
                                                            format="HH:mm"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label={`Sumário Tarde ${panel.key}`}
                                                        name={`assuntoTarde_${panel.key}`}
                                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                                    >
                                                        <Input
                                                            placeholder="Digite o assunto da tarde"
                                                            value={panel.conteudo.tarde}
                                                            onChange={e => this.handleInputChangeSumariosHora(e, panel.key, "tarde")}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={this.addPanelSumariosHora} style={{ marginTop: 16 }}>
                                        + Adicionar Painel
                                    </Button>
                                </Panel>

                                <Panel header="Assuntos" key="4">
                                    <Form.Item>
                                        {equipamentos.map(panel => (
                                            <div key={panel.key} style={{ marginBottom: 16 }}>
                                                {(manha || manhaEtarde) && (
                                                    <Form.Item
                                                        label={`Sumário Manhã ${panel.key}`}
                                                        name={`assuntoManha_${panel.key}`}
                                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                                    >
                                                        <Input
                                                            placeholder="Digite o assunto da manhã"
                                                            value={panel.conteudo.manha}
                                                            onChange={e => this.handleInputChangeEquipamentos(e, panel.key, "manha")}
                                                        />
                                                    </Form.Item>
                                                )}

                                                {(tarde || manhaEtarde) && (
                                                    <Form.Item
                                                        label={`Sumário Tarde ${panel.key}`}
                                                        name={`assuntoTarde_${panel.key}`}
                                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                                    >
                                                        <Input
                                                            placeholder="Digite o assunto da tarde"
                                                            value={panel.conteudo.tarde}
                                                            onChange={e => this.handleInputChangeEquipamentos(e, panel.key, "tarde")}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </div>
                                        ))}
                                        <Button type="dashed" onClick={this.addPanelEquipamentos} style={{ marginTop: 16 }}>
                                            + Adicionar Painel
                                        </Button>
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                        </Form>
                    </div>

                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </Link>
                        {btnIniciar && !meetExterna && !videoConfId ? (
                            <>
                                <button
                                    className="botao-principal botao-principal-border"
                                    disabled={iconLoading}
                                    onClick={() => this.validarParticipantesDuplicadosCriar(false)}
                                    style={{ marginRight: 20, display: "inline-block" }}
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Criar
                                </button>
                                <button className="botao-principal" disabled={iconLoading} onClick={() => this.guardar()}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                                </button>
                            </>
                        ) : videoConfId ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.guardar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button
                                className="botao-principal"
                                disabled={iconLoading}
                                onClick={() => this.validarParticipantesDuplicadosCriar(false)}
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                </Drawer>
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleDrawerPesqusiar: false })}
                    participantes={participantes}
                    visibleDrawerPesqusiar={visibleDrawerPesqusiar}
                    selecionarParticipante={participante => this.setState({ participantes: [...participantes, participante] })}
                />
                <Modal
                    title="Participantes em Stepmeets simultâneas"
                    visible={visibleParticipantes}
                    onOk={() => {
                        videoConfId ? this.alterar() : this.criar(iniciar);
                    }}
                    okText="Confirmar"
                    onCancel={this.cancelar}
                    cancelText="Cancelar"
                    maskClosable={false}
                    className="modal-lista-participantes"
                >
                    <div className="bloco-lista-participantes">
                        {loadingLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                            <>
                                {participantesDuplicados.map((participante, index) => (
                                    <div key={index} className="info-participante">
                                        <div className="bloco-img">
                                            <img src={participante.foto ? participante.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome">{participante.nome}</span>
                                            <p className="numero">
                                                {participante.numero}
                                                {participante.instrutor ? <span className="tag">Instrutor</span> : null}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}
const FormFormVideoConf = Form.create({ name: "form-video-conf" })(FormVideoConf);

export default FormFormVideoConf;

/*

                                <Panel header="Configurações" key="3">
                                    <Form.Item label="Sumário Manhã">
                                        {getFieldDecorator("assunto", {
                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                            initialValue: assunto
                                        })(<Input name="assunto" onChange={this.handlerChange} />)}
                                    </Form.Item>

                                    <Form.Item label="Sumário Tarde">
                                        {getFieldDecorator("assunto", {
                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                            initialValue: assunto
                                        })(<Input name="assunto" onChange={this.handlerChange} />)}
                                    </Form.Item>

                                    {sumarios.map(panel => (
                                        <div key={panel.key} style={{ marginBottom: 16 }}>
                                            {(manha || manhaEtarde) && (
                                                <Form.Item
                                                    label={`Sumário Manhã ${panel.key}`}
                                                    name={`assuntoManha_${panel.key}`}
                                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                                >
                                                    <Input
                                                        placeholder="Digite o assunto da manhã"
                                                        value={panel.conteudo.manha}
                                                        onChange={e => this.handleInputChangeSumarios(e, panel.key, "manha")}
                                                    />
                                                </Form.Item>
                                            )}

                                            {(tarde || manhaEtarde) && (
                                                <Form.Item
                                                    label={`Sumário Tarde ${panel.key}`}
                                                    name={`assuntoTarde_${panel.key}`}
                                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                                >
                                                    <Input
                                                        placeholder="Digite o assunto da tarde"
                                                        value={panel.conteudo.tarde}
                                                        onChange={e => this.handleInputChangeSumarios(e, panel.key, "tarde")}
                                                    />
                                                </Form.Item>
                                            )}
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={this.addPanelSumarios} style={{ marginTop: 16 }}>
                                        + Adicionar Painel
                                    </Button>
                                </Panel>

*/
