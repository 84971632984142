import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Form,
    Input,
    Checkbox,
    Select,
    notification,
    DatePicker,
    TimePicker,
    Tooltip,
    Icon,
    Modal,
    Radio,
    Row,
    Col,
    InputNumber
} from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState";
import noimage from "../../../images/noimage.png";
import DrawerPesquisar from "./DrawerPesquisar";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormVideoConf extends Component {
    static contextType = GlobalContext;
    state = {
        btnIniciar: false,
        assunto: "",
        dia: null,
        hora: null,
        inicio: null,
        fim: null,
        duracao: moment("01:00", "HH:mm"),
        gravarSessao: false,
        habilitarChat: false,
        totalContratado: "",
        recorrente: false,
        recorrenciaIntervalo: 1,
        recorrencia: "SEMANA",
        recorrenciaFim: null,
        //EXTERNA
        meetExterna: false,
        meetExternaConvite: "",
        //LOADING
        iconLoading: false,
        //NOTIFICAR
        notificar: false,
        //PERMISSOES
        permissaoDescarregar: 1,
        soGestoresVerDescarregar: false,
        //PARTICIANTES
        todos: false,
        participantes: [],
        //DRAWER PESQUISAR PARTICIPATES,
        visibleDrawerPesqusiar: false,
        //MODAL PARTICIPANTES DUPLICADOS
        iniciar: false,
        visibleParticipantes: false,
        loadingLista: false,
        participantesDuplicados: []
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeRecorrenciaIntervalo = value => {
        this.setState({
            recorrenciaIntervalo: value
        });
    };

    handlerChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handlerChangeRadioGeral = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    validarParticipantesDuplicadosCriar = iniciar => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.todos || this.state.participantes.length > 0)) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData();
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("todos", this.state.todos);

                axios({
                    method: "post",
                    url: "/api/gerir-videoconferencia/validar-participantes-duplicados",
                    data: item
                })
                    .then(response => {
                        if (response.data.length) {
                            this.setState({
                                iniciar,
                                visibleParticipantes: true,
                                participantesDuplicados: response.data
                            });
                        } else this.criar(iniciar);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel validar");
                        this.setState({
                            iconLoading: false,
                            visibleParticipantes: false,
                            loadingLista: false
                        });
                    });
            } else {
                if (!this.state.todos && this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
            }
        });
    };

    validarParticipantesDuplicadosAlterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.todos || this.state.participantes.length > 0)) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData();
                item.append("id", this.props.videoConfId);
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("todos", this.state.todos);

                axios({
                    method: "post",
                    url: "/api/gerir-videoconferencia/validar-participantes-duplicados",
                    data: item
                })
                    .then(response => {
                        if (response.data.length) {
                            this.setState({
                                loadingLista: false,
                                visibleParticipantes: true,
                                participantesDuplicados: response.data
                            });
                        } else this.alterar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel validar");
                        this.setState({
                            iconLoading: false
                        });
                    });
            } else {
                if (!this.state.todos && this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
            }
        });
    };

    criar = iniciar => {
        //this.props.form.validateFieldsAndScroll((err, values) => {
        //    if (!err && (this.state.todos || this.state.participantes.length > 0)) {
        if (iniciar) this.props.prepararMeet(true);

        this.setState({
            visibleParticipantes: false
        });

        var item = new FormData();
        item.append("cursoID", localStorage.getItem("codigo_curso"));
        item.append("assunto", this.state.assunto);
        item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
        item.append("hora", this.state.hora.format("HH:mm"));
        item.append("inicio", this.state.inicio.format("HH:mm"));
        item.append("fim", this.state.fim.format("HH:mm"));
        item.append("duracao", this.state.duracao.format("HH:mm"));
        item.append("gravarSessao", this.state.gravarSessao);
        item.append("habilitarChat", this.state.habilitarChat);
        item.append("notificar", this.state.notificar);
        item.append("soGestoresVerDescarregar", this.state.soGestoresVerDescarregar);
        item.append("permissaoDescarregar", this.state.permissaoDescarregar);
        item.append("participantes", JSON.stringify(this.state.participantes));
        item.append("todos", this.state.todos);
        item.append("iniciar", iniciar);
        if (this.state.meetExterna) {
            item.append("meetExterna", this.state.meetExterna);
            item.append("meetExternaConvite", this.state.meetExternaConvite);
        }
        if (this.state.recorrente) {
            item.append("recorrente", this.state.recorrente);
            item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
            item.append("recorrencia", this.state.recorrencia);
            item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
        }

        axios({
            method: "post",
            url: !this.state.recorrente ? "/api/gerir-videoconferencia/criar-nova" : "/api/gerir-videoconferencia/criar-nova-recorrencia",
            data: item
        })
            .then(response => {
                if (!this.state.recorrente) {
                    if (iniciar) {
                        if (this.state.meetExterna)
                            this.props.IniciarMeet(this.state.meetExternaLink, this.state.meetExterna, response.data.split(";")[1]);
                        else
                            this.props.IniciarMeet(
                                `/gerir-curso/${response.data
                                    .split(";")[0]
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/vconf/${response.data.split(";")[1]}/player-videoconferencia`,
                                false,
                                response.data.split(";")[1]
                            );
                    } else openNotificationWithIcon("success", "Sucesso", "Criada nova Stepmeet");
                } else {
                    if (response.data.totalFalhas > 0)
                        openNotificationWithIcon(
                            "success",
                            "Sucesso",
                            `Criadas ${response.data.totalPrevisto - response.data.totalFalhas} Stepmeets das ${
                                response.data.totalPrevisto
                            } pretendidas`
                        );
                    else openNotificationWithIcon("success", "Sucesso", `Criadas ${response.data.totalPrevisto} Stepmeets`);
                }

                this.setState({
                    iconLoading: false
                });
                this.context.atualizarEventosHoje();
                this.props.atualizarListagem();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.props.prepararMeet(false);
                this.setState({
                    iconLoading: false
                });
            });
        //}
        //else {
        //    if (!this.state.todos && this.state.participantes.length === 0)
        //        openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
        //}
        //})
    };

    alterar = () => {
        //this.props.form.validateFieldsAndScroll((err, values) => {
        //    if (!err && (this.state.todos || this.state.participantes.length > 0)) {
        //        this.setState({
        //            iconLoading: true
        //        });

        this.setState({
            visibleParticipantes: false
        });

        var item = new FormData();
        item.append("id", this.props.videoConfId);
        item.append("cursoID", localStorage.getItem("codigo_curso"));
        item.append("assunto", this.state.assunto);
        item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
        item.append("hora", this.state.hora.format("HH:mm"));
        item.append("duracao", this.state.duracao.format("HH:mm"));
        item.append("gravarSessao", this.state.gravarSessao);
        item.append("habilitarChat", this.state.habilitarChat);
        item.append("notificar", this.state.notificar);
        item.append("soGestoresVerDescarregar", this.state.soGestoresVerDescarregar);
        item.append("permissaoDescarregar", this.state.permissaoDescarregar);
        item.append("participantes", JSON.stringify(this.state.participantes));
        item.append("todos", this.state.todos);
        if (this.state.meetExterna) {
            item.append("meetExternaConvite", this.state.meetExternaConvite);
        }

        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/alterar",
            data: item
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Stepmeet alterada");
                this.setState({
                    iconLoading: false
                });
                this.context.atualizarEventosHoje();
                this.props.atualizarListagem();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({
                    iconLoading: false
                });
            });
        //    }
        //    else {
        //        if (!this.state.todos && this.state.participantes.length === 0)
        //            openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Stepmeet");
        //    }
        //});
    };

    cancelar = () => {
        this.setState({
            visibleParticipantes: false,
            iconLoading: false
        });
    };

    carregarDetalhe = id => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/detalhe",
            params: {
                id
            }
        })
            .then(response => {
                this.setState(
                    {
                        assunto: response.data.assunto,
                        dia: moment(response.data.meetingDate),
                        hora: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                        inicio: response.data.inicio ? moment(response.data.inicio, "HH:mm") : null,
                        fim: response.data.fim ? moment(response.data.fim, "HH:mm") : null,
                        duracao: moment(response.data.duracao, "HH:mm"),
                        meetExterna: response.data.meetExterna,
                        meetExternaConvite: response.data.meetExternaConvite,
                        gravarSessao: response.data.gravarSessao,
                        habilitarChat: response.data.habilitarChat,
                        soGestoresVerDescarregar: response.data.soGestoresVerDescarregar,
                        permissaoDescarregar: response.data.permissaoDescarregar,
                        todos: response.data.todos,
                        participantes: response.data.participantes
                    },
                    () => this.carregarTotalContratado()
                );
            })
            .catch(() => {});
    };

    carregarTotalContratado = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-contratado",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalContratado: response.data
                });
            })
            .catch(() => {});
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.videoConfId) {
                this.carregarDetalhe(this.props.videoConfId);
            } else {
                const inicio = moment();
                const minutos = 15 - (inicio.minute() % 15);

                const hora = moment(inicio).add(minutos, "minutes");

                this.carregarTotalContratado();
                this.setState(
                    {
                        assunto: "",
                        dia: moment(),
                        hora: hora,
                        duracao: moment("01:00", "HH:mm"),
                        gravarSessao: false,
                        habilitarChat: true,
                        recorrente: false,
                        recorrenciaIntervalo: 1,
                        recorrencia: "SEMANA",
                        recorrenciaFim: null,
                        //EXTERNA
                        meetExterna: false,
                        meetExternaConvite: "",
                        //NOTIFICAR
                        notificar: false,
                        //PERMISSOES
                        soGestoresVerDescarregar: false,
                        //PARTICIANTES
                        todos: true,
                        participantes: []
                    },
                    () => this.validarVerBotao()
                );
            }
        }
    };

    validarVerBotao = () => {
        var dataHora = `${moment(this.state.dia).format("MM/DD/YYYY")} ${moment(this.state.hora).format("HH:mm")}`;
        const agora = moment();
        const inicio = moment(dataHora);

        const diff = inicio.diff(agora);

        const diffDuration = moment.duration(diff);

        this.setState({
            btnIniciar: Math.round(diffDuration.asHours()) <= 0 && Math.round(diffDuration.asMinutes()) <= 15
        });
    };

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            btnIniciar,
            assunto,
            dia,
            hora,
            inicio,
            fim,
            duracao,
            gravarSessao,
            habilitarChat,
            totalContratado,
            recorrente,
            recorrenciaIntervalo,
            recorrencia,
            recorrenciaFim,
            //EXTERNA
            meetExterna,
            meetExternaConvite,
            //LOADING
            iconLoading,
            //NOTIFICAR
            notificar,
            //PERMISSOES
            soGestoresVerDescarregar,
            permissaoDescarregar,
            //PARTICIANTES
            todos,
            participantes,
            //DRAWER PESQUISAR PARTICIPATES,
            visibleDrawerPesqusiar,
            //MODAL PARTICIPANTES DUPLICADOS
            iniciar,
            visibleParticipantes,
            loadingLista,
            participantesDuplicados
        } = this.state;

        const { videoConfId } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias drawer-stepmeets"
                    title={videoConfId ? "Alterar Stepmeet" : "Adicionar nova Stepmeet"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Assunto">
                                    {getFieldDecorator("assunto", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: assunto
                                    })(<Input name="assunto" onChange={this.handlerChange} />)}
                                </Form.Item>
                                <Form.Item label="Dia">
                                    {getFieldDecorator("dia", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: dia
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dia"
                                            onChange={dia => this.setState({ dia }, () => this.validarVerBotao())}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora">
                                    {getFieldDecorator("hora", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: hora
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="hora"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={hora => this.setState({ hora }, () => this.validarVerBotao())}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            minuteStep={15}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Duração">
                                    {getFieldDecorator("duracao", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: duracao
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracao"
                                            defaultOpenValue={moment("01:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={duracao => this.setState({ duracao })}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Inicio">
                                    {getFieldDecorator("inicio", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: inicio
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="inicio"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={inicio => this.setState({ inicio }, () => this.validarVerBotao())}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            minuteStep={15}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Fim">
                                    {getFieldDecorator("fim", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: fim
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="fim"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={fim => this.setState({ fim }, () => this.validarVerBotao())}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            minuteStep={15}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Configurações</h3>
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox
                                        checked={meetExterna}
                                        name="meetExterna"
                                        disabled={videoConfId || gravarSessao}
                                        onChange={this.handlerChangeCheckbox}
                                    >
                                        Videoconferência noutra plataforma
                                    </Checkbox>
                                </Form.Item>
                                {meetExterna && (
                                    <>
                                        <Form.Item label="Convite" className="form-item-checkbox">
                                            {getFieldDecorator("meetExternaConvite", {
                                                rules: [{ required: true, message: "Campo obrigatório" }],
                                                initialValue: meetExternaConvite
                                            })(
                                                <TextArea
                                                    name="meetExternaConvite"
                                                    onChange={this.handlerChange}
                                                    rows={3}
                                                    style={{ height: 100 }}
                                                />
                                            )}
                                        </Form.Item>
                                    </>
                                )}
                                {this.context.conf_total_recorders > 0 && (
                                    <Form.Item className="form-item-checkbox">
                                        {getFieldDecorator("gravarSessao")(
                                            <Checkbox
                                                name="gravarSessao"
                                                checked={gravarSessao}
                                                disabled={meetExterna}
                                                onChange={this.handlerChangeCheckbox}
                                            >
                                                Gravar sessão
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                )}
                                <Form.Item className="form-item-checkbox">
                                    {getFieldDecorator("habilitarChat")(
                                        <Checkbox
                                            name="habilitarChat"
                                            checked={habilitarChat}
                                            disabled={meetExterna}
                                            onChange={this.handlerChangeCheckbox}
                                        >
                                            Habilitar Chat
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Visualização de vídeos</h3>
                                <Form.Item>
                                    <Select
                                        value={permissaoDescarregar}
                                        placeholder="Selecionar"
                                        onChange={permissaoDescarregar => this.setState({ permissaoDescarregar })}
                                    >
                                        <Option value={1}>Todos</Option>
                                        <Option value={2}>Todos instrutores e gestores</Option>
                                        <Option value={3}>Somente os participantes na sessão</Option>
                                        <Option value={4}>Somente o instrutor ou gestor que realizou</Option>
                                        <Option value={5}>Somente gestores</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            {!videoConfId && (
                                <div className="bloco">
                                    <h3 className="titulo-separador">recorrência</h3>
                                    <Form.Item className="form-item-checkbox">
                                        <Radio.Group name="recorrente" value={recorrente} onChange={this.handlerChangeRadioGeral}>
                                            <Radio value={false}>Evento único</Radio>
                                            <Radio value={true}>Recorrente</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {recorrente && (
                                        <>
                                            <Form.Item>
                                                <Row gutter={8}>
                                                    <Col span={3}>
                                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>
                                                            A cada
                                                        </label>
                                                    </Col>
                                                    <Col span={3}>
                                                        <InputNumber
                                                            value={recorrenciaIntervalo}
                                                            onChange={this.handlerChangeRecorrenciaIntervalo}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Select
                                                            className="input-100"
                                                            placeholder="Selecionar"
                                                            defaultValue={recorrencia}
                                                            onChange={this.handlerChangeSelect}
                                                        >
                                                            <Option value="SEMANA">Semana</Option>
                                                            <Option value="MES">Mês</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                            <Form.Item>
                                                <Row gutter={8}>
                                                    <Col span={4}>
                                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>
                                                            Repetir até
                                                        </label>
                                                    </Col>
                                                    <Col span={8}>
                                                        {getFieldDecorator("recorrenciaFim", {
                                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                                            initialValue: recorrenciaFim
                                                        })(
                                                            <DatePicker
                                                                placeholder="Selecionar"
                                                                format="DD-MM-YYYY"
                                                                name="dia"
                                                                onChange={recorrenciaFim => this.setState({ recorrenciaFim })}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="bloco">
                                {/*<Form.Item>
                                    Nº máx. de participantes em simulâneo: {totalContratado}
                                    <Tooltip className="info-icon" title="Nº máximo de participantes contratualizado envolvido numa ou em mais Stepmeets  simultâneas">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Form.Item>*/}
                                <h3 className="titulo-separador titulo-com-botao">Participantes</h3>
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox className="marcar-todos" checked={todos} name="todos" onChange={this.handlerChangeCheckbox}>
                                        Todos
                                        <Tooltip
                                            className="info-icon"
                                            title="Ao selecionar todos, os alunos que indicar serão considerados exceções."
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item className="form-item-checkbox">
                                    <div className="lista-participantes">
                                        <label className="label-participantes">
                                            {todos ? "Exceto os seguintes participantes" : "Incluidos os seguintes participantes"}
                                            <button
                                                className="botao-principal"
                                                onClick={() => this.setState({ visibleDrawerPesqusiar: true })}
                                            >
                                                <Icon type="plus" />
                                            </button>
                                        </label>
                                        {participantes.length > 0 ? (
                                            participantes.map((participante, index) => (
                                                <div key={index} className="participante">
                                                    <div className="info-participante">
                                                        <div className="container-img">
                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                        </div>
                                                        <div className="info">
                                                            <span className="nome">{participante.nome}</span>
                                                            <p className="numero">{participante.numero}</p>
                                                        </div>
                                                    </div>
                                                    <div className="opcoes">
                                                        <button
                                                            className="botao-excluir"
                                                            onClick={() =>
                                                                this.setState({
                                                                    participantes: [
                                                                        ...this.state.participantes.filter(x => x.id != participante.id)
                                                                    ]
                                                                })
                                                            }
                                                        >
                                                            <Icon type="delete" />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <span className="sem-registos">Sem registos</span>
                                        )}
                                    </div>
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox checked={notificar} name="notificar" onChange={this.handlerChangeCheckbox}>
                                        Notificar por e-mail
                                        <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </Link>
                        {btnIniciar && !meetExterna && !videoConfId ? (
                            <>
                                <button
                                    className="botao-principal botao-principal-border"
                                    disabled={iconLoading}
                                    onClick={() => this.validarParticipantesDuplicadosCriar(false)}
                                    style={{ marginRight: 20, display: "inline-block" }}
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Criar
                                </button>
                                <button
                                    className="botao-principal"
                                    disabled={iconLoading}
                                    onClick={() => this.validarParticipantesDuplicadosCriar(true)}
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Iniciar
                                </button>
                            </>
                        ) : videoConfId ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.validarParticipantesDuplicadosAlterar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button
                                className="botao-principal"
                                disabled={iconLoading}
                                onClick={() => this.validarParticipantesDuplicadosCriar(false)}
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                </Drawer>
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleDrawerPesqusiar: false })}
                    participantes={participantes}
                    visibleDrawerPesqusiar={visibleDrawerPesqusiar}
                    selecionarParticipante={participante => this.setState({ participantes: [...participantes, participante] })}
                />
                <Modal
                    title="Participantes em Stepmeets simultâneas"
                    visible={visibleParticipantes}
                    onOk={() => {
                        videoConfId ? this.alterar() : this.criar(iniciar);
                    }}
                    okText="Confirmar"
                    onCancel={this.cancelar}
                    cancelText="Cancelar"
                    maskClosable={false}
                    className="modal-lista-participantes"
                >
                    <div className="bloco-lista-participantes">
                        {loadingLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                            <>
                                {participantesDuplicados.map((participante, index) => (
                                    <div key={index} className="info-participante">
                                        <div className="bloco-img">
                                            <img src={participante.foto ? participante.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome">{participante.nome}</span>
                                            <p className="numero">
                                                {participante.numero}
                                                {participante.instrutor ? <span className="tag">Instrutor</span> : null}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}
const FormFormVideoConf = Form.create({ name: "form-video-conf" })(FormVideoConf);

export default FormFormVideoConf;
