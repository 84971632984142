import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Drawer,
    Table,
    Form,
    Icon,
    Input,
    Switch,
    Select,
    Spin,
    Dropdown,
    Menu,
    Checkbox,
    notification,
    Modal,
    DatePicker,
    Tooltip,
    Upload,
    Alert,
    InputNumber,
    Collapse,
    Radio
} from "antd";
import Header from "../../User/Header";
import DrawerInformacoes from "../../GerirCursos/_Aux/FormInformacoes";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import DrawerHorarios from "./_Aux/DrawerHorarios";
import { GlobalContext } from "../../GlobalState";

const { Panel } = Collapse;
const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class GestaoCursos extends Component {
    static contextType = GlobalContext;
    state = {
        edicoes: [],
        categorias: [],
        titulo_pagina: "cursos",
        //FILTROS
        pesquisa: "",
        filtro: 1,
        filtroEdicao: undefined,
        filtroCategoria: undefined,
        ativo: true,
        //TABELA
        loading_table: true,
        cursos: [],
        cursosMobile: [],
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //DRAWER
        cursoid: 0,
        disciplinaid: 0,
        capa: "",
        preview: "",
        titulo: "",
        breveDescricao: "",
        categoria: "",
        categoriaAtual: "",
        idiomas: [],
        idiomasTrascricao: [],
        descricao: "",
        certificacao: "NAO_APLICAVEL",
        portaria: "",
        componente_formacao: "",
        codigo: "",
        nr_certificacao: "",
        infos: [],
        questionario_ag: "",
        questionario_ac: "",
        questionario_ai: "",
        questionario_ig: "",
        questionario_ic: "",
        questionario_ia: "",
        formadores: [],
        dt_inicio: null,
        dt_inicio_limite: null,
        dt_disponivel: null,
        dt_fim: null,
        dt_fim_limite: null,
        precedencia: undefined,
        obrigatorio: false,
        precedencias: [],
        carga_horaria: 1,
        editavel_formadores: true,
        fechado: false,
        editavel_gestao: false,
        curso_visivel: false,
        conteudos_visiveis: false,
        tipo_inscricao: "MANUAL",
        inscricao: true,
        visivel_inscritos: true,
        esconderListaParticipantes: false,
        habilitarCompetencias: false,
        habilitarGamificacao: false,
        valorCurso: 0,
        valorCertificado: 0,
        subscricao: false,
        dt_fecho_inscricoes: null,
        tipologia: "NAO_APLICAVEL",
        horarios: [],
        horario: "",
        horarioIndex: "",
        dedicacao: "NAO_APLICAVEL",
        paginaExterna: "",
        edicao: undefined,
        ano: "",
        turma: "",
        nome_projeto: "",
        imagem_projeto: "",
        //DRAWER
        visible: false,
        visibleDisciplina: false,
        procurar: false,
        iconLoading: false,
        //SELECTS
        linguas: [],
        listaTranscricaoLinguas: [],
        listaTipos: [],
        listaEdicoes: [],
        listaCategorias: [],
        listaQuestionariosAG: [],
        listaQuestionariosAC: [],
        listaQuestionariosAI: [],
        listaQuestionariosIG: [],
        listaQuestionariosIC: [],
        listaQuestionariosIA: [],
        listaProcurarCoordenadores: [],
        listaCoordenadores: [],
        listaProcurarFormadores: [],
        listaFormadores: [],
        listaProcurarPrecedencia: [],
        listaPrecedencia: [],
        //UPLOAD IMAGEM VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //DRAWER INFORMACOES
        index: "",
        dataInfo: null,
        descricaoInfo: "",
        detalhe: false,
        visibleInformacoes: false,
        //VALIDAÇÔES INFO CURSO
        info_status: "",
        info_help: "",
        fichaCurso: [],
        fichaImagemProjeto: [],
        fichaCursoEliminado: false,
        fichaInscricao: [],
        fichaInscricaoEliminado: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL QUESTIONARIO FORMANDO
        questoesQuestionarioFormandos: [],
        visibleQuestionarioFormandos: false,
        dt_inicioQuestFormando: null,
        dt_fimQuestFormando: null,
        botaoQuestFormando: true,
        //MODAL QUESTIONARIO FORMADOR
        questoesQuestionarioFormadores: [],
        visibleQuestionarioFormadores: false,
        dt_inicioQuestFormador: null,
        dt_fimQuestFormador: null,
        botaoQuestFormador: true,
        //MODAL DUPLICAR
        visibleDuplicar: false,
        textoModal: "",
        //COPIAR/COLAR
        id_copia: 0,
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheCategoria: "",
        detalhePeriodo: "",
        detalheEstado: "",
        detalheInscritos: "",
        //DRAWER HORARIOS,
        visibleDrawerHorarios: false,
        detalheHorario: false,
        isGeral: false,
        isPorHora: false
    };

    UNSAFE_componentWillMount() {
        this.totalCursos();
        this.listarCursos();
        this.listarEdicoes();
        this.listarCategorias();
        localStorage.removeItem("modulo_aberto");
    }

    //VALIDA SE O UTILIZADOR ESTA AUTENTICADO
    componentDidUpdate() {
        if (!this.context.is_authenticated || this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR") {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            else if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
                this.setState({
                    redirect: true,
                    redirectLink: "/user"
                });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheckboxGeral = event => {
        this.props.form.resetFields();

        this.setState({
            isGeral: event.target.checked,
            isPorHora: false
        });
    };

    handlerChangeCheckboxPorHora = event => {
        this.props.form.resetFields();
        this.setState({
            isPorHora: event.target.checked,
            isGeral: false
        });
    };

    handleChangeCarga = value => {
        this.setState({ carga_horaria: value });
    };

    handleChangeInicio = date => {
        if (this.state.dt_disponivel)
            this.setState({
                dt_inicio: date
            });
        else
            this.setState({
                dt_inicio: date,
                dt_disponivel: date,
                dt_fecho_inscricoes: date
            });
    };

    handleChangeFim = date => {
        this.setState({ dt_fim: date });
    };

    handleChangeDis = date => {
        this.setState({ dt_disponivel: date });
    };

    handleChangeFechoInscricoes = date => {
        this.setState({ dt_fecho_inscricoes: date });
    };

    handleChangeData = date => {
        this.setState({ dataInfo: date });
    };

    handlerChangeSelectCategoria = value => {
        this.setState({ categoria: value });
    };

    handlerChangeSelectPrecedencia = value => {
        this.setState({ precedencia: value ? value.key : undefined });
    };

    handlerChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeValorCurso = value => {
        this.setState({ valorCurso: value });
    };

    handleChangeValorCertificado = value => {
        this.setState({ valorCertificado: value });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listarCursos();
            }
        );
    };

    handleChangeFiltro = value => {
        var titulo_pagina = "";
        if (value === 1) titulo_pagina = "cursos";
        else titulo_pagina = "cursos com disciplinas";

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                loading_table: true,
                cursos: [],
                titulo_pagina,
                filtro: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeFiltroEdicoes = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroEdicao: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeFiltroCategorias = value => {
        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                filtroCategoria: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    onClick = () => {
        this.context.atualizarState({
            lista_modulos: []
        });
    };

    abrirDetalhe = curso => {
        const detalhePeriodo = (
            <div>
                <span>
                    {curso.dt_fim
                        ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                        : moment(curso.dt_inicio).format("DD/MM/YYYY")}
                </span>
                {curso.fechado ? (
                    <div className="tag-fechado">
                        Fechado (
                        {curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
        const detalheInscritos = curso.inscritos;

        this.setState({
            visibleDetalhe: true,
            detalheCategoria: curso.categoria,
            detalhePeriodo,
            //detalheEstado,
            detalheInscritos
        });
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO
    montarMenuFiltro1 = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.editar(curso.id, false)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativar(curso.id)}>
                            Inativar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        {curso.fechado ? (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Reabrir
                            </Link>
                        ) : (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-inscricoes`}
                            onClick={() => {
                                localStorage.setItem("tipo_inscricao", curso.tipo_inscricao);
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Inscrições
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("habilitar_gamificacao", curso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", curso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.duplicar(curso.id)}>
                            Duplicar
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(curso.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativar(curso.id)}>
                            Ativar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("habilitar_gamificacao", curso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", curso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", false);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    montarMenuFiltro1Mobile = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.editar(curso.id, false)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativar(curso.id)}>
                            Inativar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        {curso.fechado ? (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Reabrir
                            </Link>
                        ) : (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-inscricoes`}
                            onClick={() => {
                                localStorage.setItem("tipo_inscricao", curso.tipo_inscricao);
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Inscrições
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.setItem("habilitar_gamificacao", curso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", curso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.duplicar(curso.id)}>
                            Duplicar
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(curso.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativar(curso.id)}>
                            Ativar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("habilitar_gamificacao", curso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", curso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", false);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO COM DISCIPLINAS
    montarMenuFiltro2 = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.showDrawerDisciplina(curso.id, curso.dt_inicio, curso.dt_fim)}>
                            Adicionar Disciplina
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-cursos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.editar(curso.id, false)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarCursosDisciplinas(curso.id)}>
                            Inativar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        {curso.fechado ? (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Reabrir
                            </Link>
                        ) : (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/gestao-cursos-disciplinas-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.duplicarCursosDisciplinas(curso.id)}>
                            Duplicar
                        </Link>
                    </Menu.Item>
                    {this.state.id_copia > 0 ? (
                        <Menu.Item>
                            <Link to="#" onClick={() => this.colar(curso.id, this.state.id_copia)}>
                                Colar disciplina
                            </Link>
                        </Menu.Item>
                    ) : null}
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-cursos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluirCursoDisciplina(curso.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarCursosDisciplinas(curso.id)}>
                            Ativar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/gestao-cursos-disciplinas-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                </Menu>
            );
        }
    };

    montarMenuFiltro2Mobile = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.showDrawerDisciplina(curso.id, curso.dt_inicio, curso.dt_fim)}>
                            Adicionar Disciplina
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-cursos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.editar(curso.id, false)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarCursosDisciplinas(curso.id)}>
                            Inativar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        {curso.fechado ? (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Reabrir
                            </Link>
                        ) : (
                            <Link to="#" onClick={() => this.fechar(curso.id, curso.fechado)}>
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/gestao-cursos-disciplinas-resultados`}
                            onClick={() => {
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.duplicarCursosDisciplinas(curso.id)}>
                            Duplicar
                        </Link>
                    </Menu.Item>
                    {this.state.id_copia > 0 ? (
                        <Menu.Item>
                            <Link to="#" onClick={() => this.colar(curso.id, this.state.id_copia)}>
                                Colar disciplina
                            </Link>
                        </Menu.Item>
                    ) : null}
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-cursos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", curso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluirCursoDisciplina(curso.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarCursosDisciplinas(curso.id)}>
                            Ativar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${curso.nome
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/gestao-cursos-disciplinas-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso", curso.codigo);
                                localStorage.setItem("nome_curso", curso.nome);
                                localStorage.setItem("codigo_curso", curso.id);
                                localStorage.removeItem("nome_curso_pai");
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA DISCIPLINA
    montarMenuFiltro2Sub = (subcurso, cursoIndex, curso) => {
        if (subcurso.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", subcurso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.editar(subcurso.id, true);
                                this.setState({ dt_inicio_limite: curso.dt_inicio, dt_fim_limite: curso.dt_fim });
                            }}
                        >
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex)}>
                            Inativar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        {subcurso.fechado ? (
                            <Link to="#" onClick={() => this.fechar(subcurso.id, subcurso.fechado)}>
                                Reabrir
                            </Link>
                        ) : (
                            <Link to="#" onClick={() => this.fechar(subcurso.id, subcurso.fechado)}>
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-inscricoes`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("tipo_inscricao", subcurso.tipo_inscricao);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Inscrições
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                                localStorage.setItem("habilitar_gamificacao", subcurso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", subcurso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.copiar(subcurso.id)}>
                            Copiar disciplina
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", subcurso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluirDisciplina(subcurso.id, cursoIndex)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex)}>
                            Ativar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                                localStorage.setItem("habilitar_gamificacao", subcurso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", subcurso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    montarMenuFiltro2SubMobile = (subcurso, cursoIndex, curso) => {
        if (subcurso.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", subcurso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(subcurso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.editar(subcurso.id, true);
                                this.setState({ dt_inicio_limite: curso.dt_inicio, dt_fim_limite: curso.dt_fim });
                            }}
                        >
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex)}>
                            Inativar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        {subcurso.fechado ? (
                            <Link to="#" onClick={() => this.fechar(subcurso.id, subcurso.fechado)}>
                                Reabrir
                            </Link>
                        ) : (
                            <Link to="#" onClick={() => this.fechar(subcurso.id, subcurso.fechado)}>
                                Fechar
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-inscricoes`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("tipo_inscricao", subcurso.tipo_inscricao);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Inscrições
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                                localStorage.setItem("habilitar_gamificacao", subcurso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", subcurso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.copiar(subcurso.id)}>
                            Copiar disciplina
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/gerir-curso/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", subcurso.id);
                                this.onClick();
                            }}
                        >
                            Aceder
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(subcurso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluirDisciplina(subcurso.id, cursoIndex)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.ativarDisciplina(subcurso.id, subcurso.ativo, cursoIndex)}>
                            Ativar
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-resultados`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                                localStorage.setItem("habilitar_gamificacao", subcurso.habilitar_gamificacao);
                                localStorage.setItem("habilitar_competencias", subcurso.habilitar_competencias);
                                localStorage.setItem("curso_ativo", true);
                            }}
                        >
                            Resultados
                    </Link>
                    </Menu.Item>*/}
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-estatisticas`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.codigo);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Estatísticas
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    copiar = subcursoid => {
        this.setState(
            {
                id_copia: subcursoid
            },
            () => {
                var cursos = this.state.cursos;
                var _cursos = [];

                cursos.forEach(curso => {
                    _cursos.push({
                        ...curso,
                        opcoes: (
                            <Dropdown overlay={this.montarMenuFiltro2(curso)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        )
                    });
                });

                this.setState({
                    cursos: _cursos
                });
            }
        );
    };

    colar = (cursoId, copiaId) => {
        confirm({
            title: "Pretende copiar a disciplina?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState(
                    {
                        visibleDuplicar: true,
                        textoModal: "copiar"
                    },
                    () => {
                        axios({
                            method: "post",
                            url: "/api/gestao-cursos/colar",
                            params: {
                                cursoId,
                                copiaId
                            }
                        })
                            .then(() => {
                                this.totalCursos();
                                this.listarCursos();
                                this.setState({
                                    visibleDuplicar: false
                                });
                            })
                            .catch(() => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível copiar disciplina");
                                this.setState({
                                    visibleDuplicar: false
                                });
                            });
                    }
                );
            }
        });
    };

    duplicar = cursoid => {
        confirm({
            title: "Pretende duplicar este curso?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState(
                    {
                        visibleDuplicar: true,
                        textoModal: "duplicar"
                    },
                    () => {
                        axios({
                            method: "post",
                            url: "/api/gestao-cursos/duplicar",
                            params: {
                                cursoid
                            }
                        })
                            .then(() => {
                                openNotificationWithIcon("success", "Sucesso", "Curso duplicado!");
                                this.totalCursos();
                                this.listarCursos();
                                this.setState({
                                    visibleDuplicar: false
                                });
                            })
                            .catch(() => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível duplicar curso");
                                this.setState({
                                    visibleDuplicar: false
                                });
                            });
                    }
                );
            }
        });
    };

    duplicarCursosDisciplinas = cursoid => {
        confirm({
            title: "Pretende duplicar este curso?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState(
                    {
                        visibleDuplicar: true,
                        textoModal: "duplicar"
                    },
                    () => {
                        axios({
                            method: "post",
                            url: "/api/gestao-cursos/duplicar-cursos-disciplinas",
                            params: {
                                cursoid
                            }
                        })
                            .then(() => {
                                openNotificationWithIcon("success", "Sucesso", "Curso duplicado!");
                                this.totalCursos();
                                this.listarCursos();
                                this.setState({
                                    visibleDuplicar: false
                                });
                            })
                            .catch(() => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível duplicar curso");
                                this.setState({
                                    visibleDuplicar: false
                                });
                            });
                    }
                );
            }
        });
    };

    //CARREGA A LISTA DE LINGUAS EXISTENTES NO STEPFORMA
    listarLinguas = () => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/listar-linguas"
        })
            .then(response => {
                this.setState({
                    linguas: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA A LISTA DE CATEGORIAS EXISTENTES NO STEPFORMA
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/lista-categorias"
        })
            .then(response => {
                this.setState({
                    categorias: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA A LISTA DE CATEGORIAS EXISTENTES NO STEPFORMA
    listarCategoriasDrawer = novo => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/lista-categorias-drawer",
            params: {
                cursoId: this.state.cursoid,
                novo
            }
        })
            .then(response => {
                this.setState({
                    listaCategorias: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarEdicoes = () => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/lista-edicoes"
        })
            .then(response => {
                this.setState({
                    edicoes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarEdicoesDrawer = novo => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/lista-edicoes-drawer",
            params: {
                cursoId: this.state.cursoid,
                novo
            }
        })
            .then(response => {
                this.setState({
                    listaEdicoes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA A LISTA DE QUESTIONARIOS EXISTENTES NO STEPFORMA
    listarQuestionarios = () => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/listar-questionarios",
            params: {
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                let listaQuestionariosAG = [];
                let listaQuestionariosAC = [];
                let listaQuestionariosAI = [];
                let listaQuestionariosIG = [];
                let listaQuestionariosIC = [];
                let listaQuestionariosIA = [];

                response.data.forEach(questionario => {
                    switch (questionario.tipo) {
                        case "ALUNO_GERAL":
                            listaQuestionariosAG = [...listaQuestionariosAG, questionario];
                            break;
                        case "ALUNO_CURSO":
                            listaQuestionariosAC = [...listaQuestionariosAC, questionario];
                            break;
                        case "ALUNO_INSTRUTORES":
                            listaQuestionariosAI = [...listaQuestionariosAI, questionario];
                            break;
                        case "INSTRUTOR_GERAL":
                            listaQuestionariosIG = [...listaQuestionariosIG, questionario];
                            break;
                        case "INSTRUTOR_CURSO":
                            listaQuestionariosIC = [...listaQuestionariosIC, questionario];
                            break;
                        case "INSTRUTOR_ALUNOS":
                            listaQuestionariosIA = [...listaQuestionariosIA, questionario];
                            break;
                    }
                });

                this.setState({
                    listaQuestionariosAG,
                    listaQuestionariosAC,
                    listaQuestionariosAI,
                    listaQuestionariosIG,
                    listaQuestionariosIC,
                    listaQuestionariosIA
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //TOTAL CURSOS DA ENTIDADE
    totalCursos = () => {
        axios({
            method: "get",
            url: "/api/gestao-cursos/total-cursos",
            params: {
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtro,
                filtroCategoria: this.state.filtroCategoria,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarCursos = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-cursos/listar-cursos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        filtro: this.state.filtro,
                        filtroEdicao: this.state.filtroEdicao,
                        filtroCategoria: this.state.filtroCategoria,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var cursos = [];
                        var cursosMobile = [];

                        response.data.map((curso, index) => {
                            cursos.push({
                                key: index,
                                id: curso.id,
                                edicao: (
                                    <div>
                                        <span>{curso.edicao}</span>
                                        {curso.ano || curso.turma ? (
                                            <span style={{ display: "block" }}>{`${curso.ano ? curso.ano : ""} ${
                                                curso.turma ? curso.turma : ""
                                            }`}</span>
                                        ) : null}
                                    </div>
                                ),
                                curso: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={curso.imagem ? curso.imagem : noimage} />
                                        </div>
                                        <div className="nome-curso">
                                            <span className="titulo-curso" title={curso.nome}>
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                        </div>
                                    </>
                                ),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                categoria: curso.categoria,
                                periodo: (
                                    <div>
                                        <span>
                                            {curso.dt_fim
                                                ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format(
                                                      "DD/MM/YYYY"
                                                  )}`
                                                : moment(curso.dt_inicio).format("DD/MM/YYYY")}
                                        </span>
                                        {curso.fechado ? (
                                            <div className="tag-fechado">
                                                Fechado (
                                                {curso.dt_fechado && (
                                                    <span style={{ fontSize: "12px" }}>
                                                        {moment(curso.dt_fechado).format("DD/MM/YYYY")})
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ),
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                dt_disponivel: curso.dt_disponivel,
                                inscritos: curso.inscritos,
                                opcoes: (
                                    <Dropdown
                                        overlay={this.state.filtro === 1 ? this.montarMenuFiltro1(curso) : this.montarMenuFiltro2(curso)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });

                            cursosMobile.push({
                                key: index,
                                id: curso.id,
                                curso:
                                    this.state.filtro === 1 ? (
                                        <>
                                            <div className="imagem-curso">
                                                <img src={curso.imagem ? curso.imagem : noimage} />
                                            </div>
                                            <div className="nome-curso">
                                                {curso.edicao ? (
                                                    <span className="texto-edicao">
                                                        {`${curso.edicao}${
                                                            curso.ano && curso.turma
                                                                ? ` / ${curso.ano} ${curso.turma}`
                                                                : `${curso.ano || curso.turma ? " / " : ""} ${curso.ano ? curso.ano : ""} ${
                                                                      curso.turma ? curso.turma : ""
                                                                  }`
                                                        }`}
                                                    </span>
                                                ) : null}
                                                <span className="titulo-curso">
                                                    {curso.codigo} - {curso.nome}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="imagem-curso">
                                                <img src={curso.imagem ? curso.imagem : noimage} />
                                            </div>
                                            <div className="nome-curso">
                                                {curso.edicao ? (
                                                    <span className="texto-edicao">
                                                        {`${curso.edicao}${
                                                            curso.ano && curso.turma
                                                                ? ` / ${curso.ano} ${curso.turma}`
                                                                : `${curso.ano || curso.turma ? " / " : ""} ${curso.ano ? curso.ano : ""} ${
                                                                      curso.turma ? curso.turma : ""
                                                                  }`
                                                        }`}
                                                    </span>
                                                ) : null}
                                                <span className="titulo-curso">
                                                    {curso.codigo} - {curso.nome}
                                                    <Dropdown
                                                        overlay={
                                                            this.state.filtro === 1
                                                                ? this.montarMenuFiltro1Mobile(curso)
                                                                : this.montarMenuFiltro2Mobile(curso)
                                                        }
                                                        placement="bottomLeft"
                                                    >
                                                        <Link to="#" className="botao-icon-configs">
                                                            <i className="fas fa-cog" />
                                                        </Link>
                                                    </Dropdown>
                                                </span>
                                            </div>
                                        </>
                                    ),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                periodo: (
                                    <div>
                                        <span>
                                            {curso.dt_fim
                                                ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format(
                                                      "DD/MM/YYYY"
                                                  )}`
                                                : moment(curso.dt_inicio).format("DD/MM/YYYY")}
                                        </span>
                                        {curso.fechado ? (
                                            <div className="tag-fechado">
                                                Fechado (
                                                {curso.dt_fechado && (
                                                    <span style={{ fontSize: "12px" }}>
                                                        {moment(curso.dt_fechado).format("DD/MM/YYYY")})
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ),
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                inscritos: curso.inscritos,
                                opcoes: (
                                    <Dropdown
                                        overlay={
                                            this.state.filtro === 1
                                                ? this.montarMenuFiltro1Mobile(curso)
                                                : this.montarMenuFiltro2Mobile(curso)
                                        }
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });
                        });

                        this.setState({
                            cursos,
                            cursosMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarSubCursos = (subcursos, cursoIndex, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                edicao: (
                    <div>
                        <span>{subcurso.edicao}</span>
                        {subcurso.ano || subcurso.turma ? (
                            <span style={{ display: "block" }}>{`${subcurso.ano ? subcurso.ano : ""} ${
                                subcurso.turma ? subcurso.turma : ""
                            }`}</span>
                        ) : null}
                    </div>
                ),
                curso: (
                    <>
                        <div className="imagem-curso">
                            <img src={subcurso.imagem ? subcurso.imagem : noimage} />
                        </div>
                        <div className="nome-curso">
                            <span className="titulo-curso" title={subcurso.nome}>
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        </div>
                    </>
                ),
                categoria: subcurso.categoria,
                periodo: (
                    <div>
                        <span>
                            {subcurso.dt_fim
                                ? `${moment(subcurso.dt_inicio).format("DD/MM/YYYY")} - ${moment(subcurso.dt_fim).format("DD/MM/YYYY")}`
                                : moment(subcurso.dt_inicio).format("DD/MM/YYYY")}
                        </span>
                        {subcurso.fechado ? (
                            <div className="tag-fechado">
                                Fechado (
                                {subcurso.dt_fechado && (
                                    <span style={{ fontSize: "12px" }}>{moment(subcurso.dt_fechado).format("DD/MM/YYYY")}</span>
                                )}
                                )
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ),
                inscritos: subcurso.inscritos,
                opcoes: (
                    <Dropdown overlay={this.montarMenuFiltro2Sub(subcurso, cursoIndex, curso)} placement="bottomLeft">
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    listarSubCursosMobile = (subcursos, cursoIndex, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                curso: (
                    <>
                        <div className="imagem-curso">
                            <img src={subcurso.imagem ? subcurso.imagem : noimage} />
                        </div>
                        <div className="nome-curso">
                            <span className="texto-edicao">
                                {`${subcurso.edicao}${
                                    subcurso.ano && subcurso.turma
                                        ? ` / ${subcurso.ano} ${subcurso.turma}`
                                        : `${subcurso.ano || subcurso.turma ? " / " : ""} ${subcurso.ano ? subcurso.ano : ""} ${
                                              subcurso.turma ? subcurso.turma : ""
                                          }`
                                }`}
                            </span>
                            <span className="titulo-curso" title={subcurso.nome}>
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        </div>
                    </>
                ),
                opcoes: (
                    <Dropdown overlay={this.montarMenuFiltro2SubMobile(subcurso, cursoIndex, curso)} placement="bottomLeft">
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    //ABRE O DRAWER E CARREGA COM OS DADOS DO CURSO PARA EDIÇÃO
    editar = (cursoid, disciplina) => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-cursos/detalhe-curso",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                var data = response.data;
                var idiomas = [];
                var idiomasTrascricao = [];
                var coordenadores = [];
                var formadores = [];
                var listaLinguas = [];
                var listaTranscricaoLinguas = [];
                var listaProcurarCoordenadores = [];
                var listaProcurarFormadores = [];
                var listaProcurarPrecedencia = [];
                var infos = [];
                var horarios = [];
                var fichaCurso = [];
                var fichaInscricao = [];
                var fichaImagemProjeto = [];

                //ADICIONA AO ARRAY TODOS OS IDIOMAS DO CURSO
                data.curso_Linguas.forEach(lingua => {
                    idiomas.push(lingua.id_lingua);
                    listaLinguas.push({
                        key: lingua.id_lingua,
                        label: lingua.lingua.nome
                    });
                });
                //ADICIONA AO ARRAY TODOS OS IDIOMAS PARA A TRANSCRIÇÃO
                data.curso_Transcricao_Linguas.forEach(transcricao => {
                    idiomasTrascricao.push(transcricao.id_lingua);
                    listaTranscricaoLinguas.push({
                        key: transcricao.id_lingua,
                        label: transcricao.lingua.nome
                    });
                });

                //ADICIONA AO ARRAY TODOS OS COORDENADORES DO CURSO
                data.curso_Formadores.forEach(coordenador => {
                    if (coordenador.responsavel) {
                        coordenadores.push(coordenador.utilizador.id);
                        listaProcurarCoordenadores.push({
                            key: coordenador.utilizador.id + "",
                            label: coordenador.utilizador.nome_completo
                        });
                    }
                });

                //ADICIONA AO ARRAY TODOS OS FORMADORES DO CURSO
                data.curso_Formadores.forEach(formador => {
                    if (!formador.responsavel) {
                        formadores.push(formador.utilizador.id);
                        listaProcurarFormadores.push({
                            key: formador.utilizador.id + "",
                            label: formador.utilizador.nome_completo
                        });
                    }
                });

                //ADICIONA AO ARRAY TODAS AS INFORMAÇÕES DO CURSO
                data.curso_Informacoes.forEach(info => {
                    infos.push({
                        id: info.id,
                        data: moment(info.dt_criado),
                        descricao: info.texto
                    });
                });

                //ADICIONA AO ARRAY TODOS OS HORARIOS
                data.cursos_Aulas.forEach(horario => {
                    horarios.push({
                        id: horario.id,
                        data: horario.data,
                        hora_ini: horario.hora_ini,
                        hora_fim: horario.hora_fim,
                        sala: horario.sala,
                        morada: horario.morada,
                        localidade: horario.localidade
                    });
                });

                if (data.precedencia)
                    listaProcurarPrecedencia.push({
                        key: data.precedencia.id,
                        label: data.precedencia.nome
                    });

                if (data.ficha)
                    fichaCurso.push({
                        uid: cursoid,
                        name: data.ficha_nome,
                        status: "done",
                        url: data.ficha
                    });

                if (data.imagem_projeto)
                    fichaImagemProjeto.push({
                        uid: cursoid,
                        name: data.imagem_projeto_nome,
                        status: "done",
                        url: data.ficha
                    });

                if (data.ficha_inscricao)
                    fichaInscricao.push({
                        uid: cursoid,
                        name: data.ficha_inscricao_nome,
                        status: "done",
                        url: data.ficha_inscricao
                    });

                this.setState(
                    {
                        cursoid: !disciplina ? cursoid : 0,
                        disciplinaid: disciplina ? cursoid : 0,
                        capa: data.imagem,
                        preview: data.imagem,
                        titulo: data.nome,
                        breveDescricao: data.objectivo,
                        categoria: data.categoria.id,
                        categoriaAtual: data.categoria.id,
                        descricao: data.descricao,
                        carga_horaria: data.carga_horaria,
                        certificacao: data.certificacao,
                        portaria: data.portaria ? data.portaria : "",
                        componente_formacao: data.componente_formacao ? data.componente_formacao : "",
                        codigo: data.codigo ? data.codigo : "",
                        nr_certificacao: data.nr_certificacao ? data.nr_certificacao : "",
                        idiomas,
                        idiomasTrascricao,
                        formadores,
                        coordenadores,
                        listaLinguas,
                        listaTranscricaoLinguas,
                        listaProcurarCoordenadores,
                        listaProcurarFormadores,
                        listaProcurarPrecedencia,
                        infos,
                        precedencia: data.precedencia ? data.precedencia.id : undefined,
                        obrigatorio: data.obrigatoria,
                        dt_inicio: data.dt_inicio ? moment(data.dt_inicio) : null,
                        dt_fim: data.dt_fim ? moment(data.dt_fim) : null,
                        dt_disponivel: data.dt_disponivel ? moment(data.dt_disponivel) : null,
                        questionario_ag: data.questionario_ag ? data.questionario_ag : undefined,
                        questionario_ac: data.questionario_ac ? data.questionario_ac : undefined,
                        questionario_ai: data.questionario_ai ? data.questionario_ai : undefined,
                        questionario_ig: data.questionario_ig ? data.questionario_ig : undefined,
                        questionario_ic: data.questionario_ic ? data.questionario_ic : undefined,
                        questionario_ia: data.questionario_ia ? data.questionario_ia : undefined,
                        editavel_formadores: data.editavel_formadores,
                        fechado: data.fechado,
                        editavel_gestao: data.editavel_gestao,
                        curso_visivel: data.curso_visivel,
                        conteudos_visiveis: data.conteudos_visiveis,
                        tipo_inscricao: data.tipo_inscricao,
                        inscricao: data.inscricao,
                        visivel_inscritos: data.visivel_inscritos,
                        esconderListaParticipantes: response.data.esconderListaParticipantes,
                        habilitarCompetencias: response.data.habilitar_competencias,
                        habilitarGamificacao: response.data.habilitar_gamificacao,
                        valorCurso: (response.data.valorCurso + "").replace(".", ","),
                        valorCertificado: response.data.valorCertificado,
                        subscricao: response.data.subscricao,
                        dt_fecho_inscricoes: response.data.dt_fecho_inscricoes ? moment(response.data.dt_fecho_inscricoes) : null,
                        tipologia: response.data.tipologia,
                        horarios,
                        dedicacao: response.data.dedicacao,
                        paginaExterna: response.data.pagina_externa ? response.data.pagina_externa : "",
                        visible: !disciplina,
                        visibleDisciplina: disciplina,
                        fichaCurso,
                        fichaInscricao,
                        edicao: response.data.id_edicao,
                        ano: response.data.ano ? response.data.ano : "",
                        turma: response.data.turma ? response.data.turma : "",
                        nome_projeto: response.data.nome_projeto ? response.data.nome_projeto : "",
                        isGeral: response.data.sumario_geral,
                        isPorHora: response.data.sumario_por_hora,
                        fichaImagemProjeto
                    },
                    () => {
                        this.listarEdicoesDrawer(false);
                        this.listarCategoriasDrawer(false);
                    }
                );
            })
            .catch(() => {});
    };

    //FECHAR O CURSO SELECIONADO
    fechar = (cursoid, estado) => {
        confirm({
            title: `Pretende ${estado ? "reabrir" : "fechar"} este curso ?`,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-cursos/fechar-curso",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        if (estado) openNotificationWithIcon("success", "Sucesso", "Curso reaberto!");
                        else openNotificationWithIcon("success", "Sucesso", "Curso fechado!");

                        this.listarCursos();
                    })
                    .catch(() => {
                        if (estado) openNotificationWithIcon("error", "Erro", "Não foi possível reabrir o curso");
                        else openNotificationWithIcon("error", "Erro", "Não foi possível fechar o curso");
                    });
            }
        });
    };

    //EXCLUIR O CURSO SELECIONADO
    excluir = cursoid => {
        confirm({
            title: "Pretende excluir este curso (ALERTA: Esta opção remove tudo, conteúdos, inscrições e instrutores) ?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-cursos/excluir-curso",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Curso excluido!");
                        var cursos = this.state.cursos.filter(x => x.id != cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                        //this.listarCursos();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o curso");
                    });
            }
        });
    };

    //EXCLUIR O CURSO SELECIONADO
    excluirCursoDisciplina = cursoid => {
        confirm({
            title: "Pretende excluir este curso (ALERTA: Esta opção remove tudo, conteúdos, inscrições e instrutores)?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-cursos/excluir-curso-disciplina",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Curso excluido!");
                        var cursos = this.state.cursos.filter(x => x.id != cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                        //this.listarCursos();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o curso");
                    });
            }
        });
    };

    //EXCLUIR O CURSO SELECIONADO
    excluirDisciplina = (cursoid, cursoIndex) => {
        confirm({
            title: "Pretende excluir esta disciplina (ALERTA: Esta opção remove tudo, conteúdos, inscrições e instrutores)?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-cursos/excluir-disciplina",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Disciplina excluida!");
                        var cursos = this.state.cursos;
                        cursos[cursoIndex].subcursos = cursos[cursoIndex].subcursos.filter(x => x.id != cursoid);

                        this.setState({
                            cursos
                        });
                        //this.listarCursos();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a disciplina");
                    });
            }
        });
    };

    //ATIVAR E INATIVAR O CURSO
    ativar = cursoid => {
        confirm({
            title: this.state.ativo ? "Pretende inativar este curso?" : "Pretende ativar este curso?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-cursos/ativar-curso",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Curso inativado!" : "Curso ativado!");

                        var cursos = this.state.cursos.filter(x => x.id != cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                        this.listarCategorias();
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar o curso!" : "Não foi possível ativar o curso!"
                        );
                    });
            }
        });
    };

    //ATIVAR E INATIVAR O CURSO
    ativarCursosDisciplinas = cursoid => {
        confirm({
            title: this.state.ativo ? "Pretende inativar este curso?" : "Pretende ativar este curso?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-cursos/ativar-curso-disciplinas",
                    params: {
                        cursoid: cursoid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Curso inativado!" : "Curso ativado!");

                        var cursos = this.state.cursos.filter(x => x.id != cursoid);
                        var pagination = this.state.pagination;
                        pagination.total = pagination.total - 1;

                        this.setState({
                            cursos,
                            pagination
                        });
                        this.listarCategorias();
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar o curso!" : "Não foi possível ativar o curso!"
                        );
                    });
            }
        });
    };

    //ATIVAR E INATIVAR O CURSO
    ativarDisciplina = (id, ativo, cursoIndex) => {
        confirm({
            title: ativo ? "Pretende inativar esta Disciplina?" : "Pretende ativar esta Disciplina?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-cursos/ativar-curso",
                    params: {
                        cursoId: id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Disciplina inativada!" : "Disciplina ativada!");

                        var cursos = this.state.cursos;
                        var subIndex = cursos[cursoIndex].subcursos.findIndex(x => x.id == id);
                        cursos[cursoIndex].subcursos[subIndex].ativo = !cursos[cursoIndex].subcursos[subIndex].ativo;

                        this.setState({
                            cursos
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a Disciplina!" : "Não foi possível ativar a Disciplina!"
                        );
                    });
            }
        });
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024) {
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            capa: "",
            preview: ""
        });
    };

    //CRIA UM NOVO CURSO
    criarCurso = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo.trim());
                item.append("codigo", this.state.codigo);
                item.append("breveDescricao", this.state.breveDescricao);
                item.append("categoriaid", this.state.categoria);
                item.append("idiomas", JSON.stringify(this.state.idiomas));
                item.append("idiomasTrascricao", JSON.stringify(this.state.idiomasTrascricao));
                item.append("descricao", this.state.descricao);
                item.append("carga_horaria", this.state.carga_horaria);
                item.append("certificacao", this.state.certificacao);
                if (this.state.certificacao == "CERTIFICADO") {
                    item.append("portaria", this.state.portaria);
                    item.append("componente_formacao", this.state.componente_formacao);
                }
                item.append("infos", JSON.stringify(this.state.infos));
                if (this.state.questionario_ag) {
                    item.append("questionario_ag", this.state.questionario_ag);
                }
                if (this.state.questionario_ac) {
                    item.append("questionario_ac", this.state.questionario_ac);
                }
                if (this.state.questionario_ai) {
                    item.append("questionario_ai", this.state.questionario_ai);
                }
                if (this.state.questionario_ig) {
                    item.append("questionario_ig", this.state.questionario_ig);
                }
                if (this.state.questionario_ic) {
                    item.append("questionario_ic", this.state.questionario_ic);
                }
                if (this.state.questionario_ia) {
                    item.append("questionario_ia", this.state.questionario_ia);
                }
                if (this.state.coordenadores) item.append("coordenadores", JSON.stringify(this.state.coordenadores));
                item.append("formadores", JSON.stringify(this.state.formadores));
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                if (this.state.dt_fim) {
                    item.append("dt_fim", this.state.dt_fim.format("YYYY/MM/DD"));
                }
                if (this.state.dt_disponivel) {
                    item.append("dt_disponivel", this.state.dt_disponivel.format("YYYY/MM/DD"));
                }
                if (this.state.precedencia) {
                    item.append("precedencia", this.state.precedencia);
                    item.append("obrigatoria", this.state.obrigatorio);
                }
                item.append("editavel_formadores", this.state.editavel_formadores);
                item.append("editavel_gestao", this.state.editavel_gestao);
                item.append("curso_visivel", this.state.curso_visivel);
                item.append("conteudos_visiveis", this.state.conteudos_visiveis);
                item.append("tipo_inscricao", this.state.tipo_inscricao);
                item.append("inscricao", this.state.inscricao);
                item.append("visivel_inscritos", this.state.visivel_inscritos);
                item.append("esconderListaParticipantes", this.state.esconderListaParticipantes);
                item.append("habilitar_competencias", this.state.habilitarCompetencias);
                item.append("habilitar_gamificacao", this.state.habilitarGamificacao);
                item.append("valorCurso", (this.state.valorCurso + "").replace(",", "."));
                item.append("valorCertificado", this.state.valorCertificado);
                item.append("subscricao", this.state.subscricao);
                if (this.state.dt_fecho_inscricoes) {
                    item.append("dt_fecho_inscricoes", this.state.dt_fecho_inscricoes.format("YYYY/MM/DD"));
                }
                item.append("tipologia", this.state.tipologia);
                item.append("horarios", JSON.stringify(this.state.horarios));
                item.append("dedicacao", this.state.dedicacao);
                item.append("pagina_externa", this.state.paginaExterna);
                this.state.fichaCurso.forEach(file => {
                    item.append("ficha", file);
                });

                this.state.fichaImagemProjeto.forEach(file => {
                    item.append("imagem_projeto", file);
                });

                this.state.fichaInscricao.forEach(file => {
                    item.append("ficha_inscricao", file);
                });

                item.append("subcursos", this.state.filtro === 2);

                if (this.state.edicao) item.append("edicaoid", this.state.edicao);
                item.append("ano", this.state.ano);
                item.append("turma", this.state.turma);
                item.append("nome_projeto", this.state.nome_projeto);
                item.append("imagem_projeto", this.state.imagem_projeto);
                //item.append("sumario_geral", this.state.isGeral);
                //item.append("sumario_por_hora", this.state.isPorHora);

                axios({
                    method: "post",
                    url: "/api/gestao-cursos/adicionar-curso",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado novo curso!");
                        this.listarCursos();
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //CRIA UM NOVO CURSO
    criarDisciplina = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("cursoid", this.state.cursoid);
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo.trim());
                item.append("codigo", this.state.codigo);
                item.append("breveDescricao", this.state.breveDescricao);
                item.append("categoriaid", this.state.categoria);
                item.append("idiomas", JSON.stringify(this.state.idiomas));
                item.append("idiomasTrascricao", JSON.stringify(this.state.idiomasTrascricao));
                item.append("descricao", this.state.descricao);
                item.append("carga_horaria", this.state.carga_horaria);
                item.append("certificacao", this.state.certificacao);
                if (this.state.certificacao == "CERTIFICADO") {
                    item.append("portaria", this.state.portaria);
                    item.append("componente_formacao", this.state.componente_formacao);
                }
                item.append("infos", JSON.stringify(this.state.infos));
                if (this.state.questionario_ag) {
                    item.append("questionario_ag", this.state.questionario_ag);
                }
                if (this.state.questionario_ac) {
                    item.append("questionario_ac", this.state.questionario_ac);
                }
                if (this.state.questionario_ai) {
                    item.append("questionario_ai", this.state.questionario_ai);
                }
                if (this.state.questionario_ig) {
                    item.append("questionario_ig", this.state.questionario_ig);
                }
                if (this.state.questionario_ic) {
                    item.append("questionario_ic", this.state.questionario_ic);
                }
                if (this.state.questionario_ia) {
                    item.append("questionario_ia", this.state.questionario_ia);
                }
                item.append("coordenadores", JSON.stringify(this.state.coordenadores));
                item.append("formadores", JSON.stringify(this.state.formadores));
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                if (this.state.dt_fim) {
                    item.append("dt_fim", this.state.dt_fim.format("YYYY/MM/DD"));
                }
                if (this.state.dt_disponivel) {
                    item.append("dt_disponivel", this.state.dt_disponivel.format("YYYY/MM/DD"));
                }
                if (this.state.precedencia) {
                    item.append("precedencia", this.state.precedencia);
                    item.append("obrigatoria", this.state.obrigatorio);
                }
                item.append("editavel_formadores", this.state.editavel_formadores);
                item.append("editavel_gestao", this.state.editavel_gestao);
                item.append("curso_visivel", this.state.curso_visivel);
                item.append("conteudos_visiveis", this.state.conteudos_visiveis);
                item.append("tipo_inscricao", this.state.tipo_inscricao);
                item.append("inscricao", this.state.inscricao);
                item.append("visivel_inscritos", this.state.visivel_inscritos);
                item.append("esconderListaParticipantes", this.state.esconderListaParticipantes);
                item.append("habilitar_competencias", this.state.habilitarCompetencias);
                item.append("habilitar_gamificacao", this.state.habilitarGamificacao);
                item.append("valorCurso", (this.state.valorCurso + "").replace(",", "."));
                item.append("valorCertificado", this.state.valorCertificado);
                item.append("subscricao", this.state.subscricao);
                if (this.state.dt_fecho_inscricoes) {
                    item.append("dt_fecho_inscricoes", this.state.dt_fecho_inscricoes.format("YYYY/MM/DD"));
                }
                item.append("tipologia", this.state.tipologia);
                item.append("horarios", JSON.stringify(this.state.horarios));
                item.append("dedicacao", this.state.dedicacao);
                item.append("pagina_externa", this.state.paginaExterna);
                this.state.fichaCurso.forEach(file => {
                    item.append("ficha", file);
                });

                this.state.fichaImagemProjeto.forEach(file => {
                    item.append("imagem_projeto", file);
                });

                this.state.fichaInscricao.forEach(file => {
                    item.append("ficha_inscricao", file);
                });

                if (this.state.edicao) item.append("edicaoid", this.state.edicao);
                item.append("ano", this.state.ano);
                item.append("turma", this.state.turma);
                item.append("nome_projeto", this.state.nome_projeto);
                item.append("imagem_projeto", this.state.imagem_projeto);
                item.append("sumario_geral", this.state.isGeral);
                item.append("sumario_por_hora", this.state.isPorHora);

                axios({
                    method: "post",
                    url: "/api/gestao-cursos/adicionar-disciplina",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionada nova disciplina!");
                        this.listarCursos();
                        this.setState({
                            visibleDisciplina: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA O CURSO
    guardarCurso = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("cursoid", this.state.visible ? this.state.cursoid : this.state.disciplinaid);
                item.append("capa", this.state.capa);
                item.append("imagem", this.state.capa);
                item.append("titulo", this.state.titulo.trim());
                item.append("codigo", this.state.codigo);
                item.append("breveDescricao", this.state.breveDescricao);
                item.append("categoriaid", this.state.categoria);
                item.append("idiomas", JSON.stringify(this.state.idiomas));
                item.append("idiomasTrascricao", JSON.stringify(this.state.idiomasTrascricao));
                item.append("descricao", this.state.descricao);
                item.append("carga_horaria", this.state.carga_horaria);
                item.append("certificacao", this.state.certificacao);
                if (this.state.certificacao == "CERTIFICADO") {
                    item.append("portaria", this.state.portaria);
                    item.append("componente_formacao", this.state.componente_formacao);
                }
                item.append("infos", JSON.stringify(this.state.infos));
                if (this.state.questionario_ag) {
                    item.append("questionario_ag", this.state.questionario_ag);
                }
                if (this.state.questionario_ac) {
                    item.append("questionario_ac", this.state.questionario_ac);
                }
                if (this.state.questionario_ai) {
                    item.append("questionario_ai", this.state.questionario_ai);
                }
                if (this.state.questionario_ig) {
                    item.append("questionario_ig", this.state.questionario_ig);
                }
                if (this.state.questionario_ic) {
                    item.append("questionario_ic", this.state.questionario_ic);
                }
                if (this.state.questionario_ia) {
                    item.append("questionario_ia", this.state.questionario_ia);
                }
                if (this.state.coordenadores) item.append("coordenadores", JSON.stringify(this.state.coordenadores));
                item.append("formadores", JSON.stringify(this.state.formadores));
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                if (this.state.dt_fim) {
                    item.append("dt_fim", this.state.dt_fim.format("YYYY/MM/DD"));
                }
                if (this.state.dt_disponivel) {
                    item.append("dt_disponivel", this.state.dt_disponivel.format("YYYY/MM/DD"));
                }
                if (this.state.precedencia) {
                    item.append("precedencia", this.state.precedencia);
                    item.append("obrigatoria", this.state.obrigatorio);
                }
                item.append("editavel_formadores", this.state.editavel_formadores);
                item.append("editavel_gestao", this.state.editavel_gestao);
                item.append("curso_visivel", this.state.curso_visivel);
                item.append("conteudos_visiveis", this.state.conteudos_visiveis);
                item.append("tipo_inscricao", this.state.tipo_inscricao);
                item.append("inscricao", this.state.inscricao);
                item.append("visivel_inscritos", this.state.visivel_inscritos);
                item.append("esconderListaParticipantes", this.state.esconderListaParticipantes);
                item.append("habilitar_competencias", this.state.habilitarCompetencias);
                item.append("habilitar_gamificacao", this.state.habilitarGamificacao);
                item.append("valorCurso", (this.state.valorCurso + "").replace(",", "."));
                item.append("valorCertificado", this.state.valorCertificado);
                item.append("subscricao", this.state.subscricao);
                if (this.state.dt_fecho_inscricoes) {
                    item.append("dt_fecho_inscricoes", this.state.dt_fecho_inscricoes.format("YYYY/MM/DD"));
                }
                item.append("tipologia", this.state.tipologia);
                item.append("horarios", JSON.stringify(this.state.horarios));
                item.append("dedicacao", this.state.dedicacao);
                item.append("pagina_externa", this.state.paginaExterna);
                this.state.fichaCurso.forEach(file => {
                    item.append("ficha", file);
                });

                this.state.fichaImagemProjeto.forEach(file => {
                    item.append("imagem_projeto", file);
                });

                this.state.fichaInscricao.forEach(file => {
                    item.append("ficha_inscricao", file);
                });

                if (this.state.edicao) item.append("edicaoid", this.state.edicao);
                item.append("ano", this.state.ano);
                item.append("turma", this.state.turma);
                item.append("nome_projeto", this.state.nome_projeto);
                item.append("imagem_projeto", this.state.imagem_projeto);
                //item.append("sumario_geral", this.state.isGeral);
                //item.append("sumario_por_hora", this.state.isPorHora);

                axios({
                    method: "post",
                    url: "/api/gestao-cursos/alterar-curso",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon(
                            "success",
                            "Sucesso",
                            this.state.visible ? "Curso editado com sucesso!" : "Disciplina editada com sucesso!"
                        );
                        this.listarCursos();
                        this.setState({
                            visible: false,
                            visibleDisciplina: false,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ADICIONA UMA NOVA INFORMAÇÃO AO CURSO DURANTE A EDIÇÃO OU ADIÇÃO
    adicionarInfo = () => {
        var infos = this.state.infos;

        infos.push({
            id: 0,
            data: this.state.dataInfo,
            descricao: this.state.descricaoInfo
        });

        this.setState({
            infos,
            visibleInformacoes: false,
            info_status: "",
            info_help: ""
        });
    };

    //ABRE A INFORMAÇÃO DO CURSO
    detalheInfo = index => {
        var infos = this.state.infos;

        this.setState({
            index,
            dataInfo: infos[index].data,
            descricaoInfo: infos[index].descricao,
            detalhe: true,
            visibleInformacoes: true
        });
    };

    //ATUALIZA A INFORMAÇÃO DO CURSO
    editarInfo = index => {
        var infos = this.state.infos;

        infos[index].data = this.state.dataInfo;
        infos[index].descricao = this.state.descricaoInfo;

        this.setState({
            infos,
            visibleInformacoes: false
        });
    };

    //EXCLUI A INFORMAÇÃO
    excluirInfo = index => {
        confirm({
            title: "Pretende excluir esta informação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                var infos = this.state.infos;

                infos.splice(index, 1);

                this.setState({
                    infos
                });
            }
        });
    };

    //MONTA AS OPÇÕES DE MENU POR CADA INFORMAÇÃO NUM CURSO
    montarMenu = index => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalheInfo(index)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluirInfo(index)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuHorario = index => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalheHorario(index)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluirHorario(index)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //PROCURA OS COORDENADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarCoordenadores = value => {
        if (value.length === 4) {
            this.setState(
                {
                    listaCoordenadores: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-cursos/procurar-formadores",
                        params: {
                            nome: value
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaCoordenadores: response.data,
                                listaCoordenadoresBackUp: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar Coordenadores!");
                        });
                }
            );
        } else if (value.length > 4) {
            if (this.state.listaCoordenadoresBackUp) {
                var _listaCoordenadores = this.state.listaCoordenadoresBackUp;

                this.setState({
                    listaCoordenadores: _listaCoordenadores.filter(x => x.nome_completo.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaCoordenadores: []
            });
        }
    };

    //SELECIONA O COORDENADOR
    checkCoordenadores = value => {
        var coordenadores = [];
        for (var i = 0; i < value.length; i++) {
            coordenadores.push(value[i].key);
        }

        this.setState({
            coordenadores,
            listaProcurarCoordenadores: value,
            listaCoordenadores: []
        });
    };

    //PROCURA OS FORMADORES DA ENTIDADE COM BASE NO INSERT DO UTILZIADOR
    procurarFormadores = value => {
        if (value.length === 4) {
            this.setState(
                {
                    listaFormadores: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-cursos/procurar-formadores",
                        params: {
                            nome: value
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaFormadores: response.data,
                                listaFormadoresBackUp: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar formadores!");
                        });
                }
            );
        } else if (value.length > 4) {
            if (this.state.listaFormadoresBackUp) {
                var _listaFormadores = this.state.listaFormadoresBackUp;

                this.setState({
                    listaFormadores: _listaFormadores.filter(x => x.nome_completo.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaFormadores: []
            });
        }
    };

    //SELECIONA O FORMADOR
    checkFormadores = value => {
        var formadores = [];
        for (var i = 0; i < value.length; i++) {
            formadores.push(value[i].key);
        }

        this.setState({
            formadores,
            listaProcurarFormadores: value,
            listaFormadores: []
        });
    };

    //PROCURA OS COORDENADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarPrecedencia = value => {
        if (value.length === 4) {
            this.setState(
                {
                    listaPrecedencia: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-cursos/procurar-precedencia",
                        params: {
                            nome: value,
                            cursoId: this.state.cursoid
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaPrecedencia: response.data,
                                listaPrecedenciaBackUp: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar precedências!");
                        });
                }
            );
        } else if (value.length > 4) {
            if (this.state.listaPrecedenciaBackUp) {
                var _listaPrecedencia = this.state.listaPrecedenciaBackUp;

                this.setState({
                    listaPrecedencia: _listaPrecedencia.filter(x => x.nome.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaPrecedencia: []
            });
        }
    };

    //PROCURA OS COORDENADORES DA ENTIDADE COM BASE NO INSERT DO UTILIZADOR
    procurarPrecedenciaDisciplinas = value => {
        if (value.length === 4) {
            this.setState(
                {
                    listaPrecedencia: [],
                    procurar: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-cursos/procurar-precedencia-disciplinas",
                        params: {
                            nome: value,
                            cursoId: this.state.cursoid
                        }
                    })
                        .then(response => {
                            this.setState({
                                listaPrecedencia: response.data,
                                listaPrecedenciaBackUp: response.data,
                                procurar: false
                            });
                        })
                        .catch(() => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar precedências!");
                        });
                }
            );
        } else if (value.length > 4) {
            if (this.state.listaPrecedenciaBackUp) {
                var _listaPrecedencia = this.state.listaPrecedenciaBackUp;

                this.setState({
                    listaPrecedencia: _listaPrecedencia.filter(x => x.nome.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaPrecedencia: []
            });
        }
    };

    //ABRE O DRAWER COM O FORM DE UM CURSO VAZIO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState(
            {
                cursoid: 0,
                disciplinaid: 0,
                capa: "",
                preview: "",
                titulo: "",
                breveDescricao: "",
                categoria: "",
                categoriaAtual: "",
                idiomas: [],
                idiomasTrascricao: [],
                descricao: "",
                certificacao: "NAO_APLICAVEL",
                portaria: "",
                componente_formacao: "",
                codigo: "",
                nr_certificacao: "",
                infos: [],
                questionario_ag: "",
                questionario_ac: "",
                questionario_ai: "",
                questionario_ig: "",
                questionario_ic: "",
                questionario_ia: "",
                formadores: [],
                dt_inicio: null,
                dt_inicio_limite: null,
                dt_disponivel: null,
                dt_fim: null,
                dt_fim_limite: null,
                precedencia: undefined,
                obrigatorio: false,
                precedencias: [],
                carga_horaria: 1,
                editavel_formadores: true,
                fechado: false,
                editavel_gestao: false,
                curso_visivel: false,
                conteudos_visiveis: false,
                inscricao: true,
                visivel_inscritos: true,
                esconderListaParticipantes: false,
                habilitarCompetencias: false,
                habilitarGamificacao: false,
                valorCurso: 0,
                valorCertificado: 0,
                subscricao: false,
                dt_fecho_inscricoes: null,
                tipologia: "NAO_APLICAVEL",
                dedicacao: "NAO_APLICAVEL",
                paginaExterna: "",
                edicao: undefined,
                ano: "",
                turma: "",
                //GERAL
                listaLinguas: [],
                listaTranscricaoLinguas: [],
                listaProcurarCoordenadores: [],
                listaCoordenadores: [],
                listaProcurarFormadores: [],
                listaFormadores: [],
                listaProcurarPrecedencia: [],
                listaPrecedencia: [],
                visible: true,
                fichaCurso: [],
                fichaImagemProjeto: [],
                fichaInscricao: []
            },
            () => {
                this.listarEdicoesDrawer(true);
                this.listarCategoriasDrawer(true);
            }
        );
    };

    showDrawerDisciplina = (cursoId, dt_inicio, dt_fim) => {
        this.props.form.resetFields();
        this.setState(
            {
                cursoid: cursoId,
                disciplinaid: 0,
                capa: "",
                preview: "",
                titulo: "",
                breveDescricao: "",
                categoria: "",
                categoriaAtual: "",
                idiomas: [],
                idiomasTrascricao: [],
                descricao: "",
                certificacao: "NAO_APLICAVEL",
                portaria: "",
                componente_formacao: "",
                codigo: "",
                nr_certificacao: "",
                infos: [],
                questionario_ag: "",
                questionario_ac: "",
                questionario_ai: "",
                questionario_ig: "",
                questionario_ic: "",
                questionario_ia: "",
                formadores: [],
                dt_inicio: null,
                dt_inicio_limite: null,
                dt_disponivel: null,
                dt_fim: null,
                dt_fim_limite: null,
                precedencia: undefined,
                obrigatorio: false,
                precedencias: [],
                carga_horaria: 1,
                editavel_formadores: true,
                fechado: false,
                editavel_gestao: false,
                curso_visivel: false,
                conteudos_visiveis: false,
                inscricao: true,
                visivel_inscritos: true,
                esconderListaParticipantes: false,
                habilitarCompetencias: false,
                habilitarGamificacao: false,
                valorCurso: 0,
                valorCertificado: 0,
                subscricao: false,
                dt_fecho_inscricoes: null,
                tipologia: "NAO_APLICAVEL",
                dedicacao: "NAO_APLICAVEL",
                paginaExterna: "",
                edicao: undefined,
                ano: "",
                turma: "",
                //GERAL
                listaLinguas: [],
                listaTranscricaoLinguas: [],
                listaProcurarCoordenadores: [],
                listaCoordenadores: [],
                listaProcurarFormadores: [],
                listaFormadores: [],
                listaProcurarPrecedencia: [],
                listaPrecedencia: [],
                visibleDisciplina: true,
                fichaCurso: [],
                fichaImagemProjeto: [],
                fichaInscricao: []
            },
            () => {
                this.listarCategoriasDrawer(true);
                this.listarEdicoesDrawer(true);
            }
        );
    };

    //FECHA O DRAWER DO CURSO SEM GUARDAR
    onClose = () => {
        this.setState({
            visible: false,
            visibleDisciplina: false,
            itemArray: []
        });
    };

    //ABRE O DRAWER DE UMA INFORMAÇÃO NO CURSO A VAZIO
    showDrawerInformacoes = () => {
        this.setState({
            index: "",
            dataInfo: moment(),
            descricaoInfo: "",
            detalhe: false,
            visibleInformacoes: true
        });
    };

    //FECHA O DRAWER DE UMA INFORMAÇÃO SEM GUARDAR
    onCloseInformacoes = () => {
        this.setState({
            visibleInformacoes: false
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = ["pdf", "png"];
        var valido = false;

        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) valido = true;
        }

        return valido;
    };

    gerarDocFormandos = () => {
        if (this.state.dt_inicioQuestFormando && this.state.dt_fimQuestFormando)
            this.setState({
                botaoQuestFormando: false
            });
        else
            this.setState({
                botaoQuestFormando: true
            });
    };

    carregaRespostasQuestFormando = lista => {
        var questoes = [
            ["Inquérito de Satisfação Formandos", moment().format("DD/MM/YYYY")],
            [this.state.dt_inicioQuestFormando ? "Data inicio: " + moment(this.state.dt_inicioQuestFormando).format("DD/MM/YYYY") : ""],
            [this.state.dt_fimQuestFormando ? "Data fim: " + moment(this.state.dt_fimQuestFormando).format("DD/MM/YYYY") : ""],
            ["Curso", "Nº Formando", "Nome", "Questão", "Resposta", "Data/Hora"]
        ];

        return questoes;
    };

    gerarDocFormadores = () => {
        if (this.state.dt_inicioQuestFormador && this.state.dt_fimQuestFormador)
            this.setState({
                botaoQuestFormador: false
            });
        else
            this.setState({
                botaoQuestFormador: true
            });
    };

    carregaRespostasQuestFormador = lista => {
        var questoes = [
            ["Inquérito de Satisfação Formadores", moment().format("DD/MM/YYYY")],
            [this.state.dt_inicioQuestFormando ? "Data inicio: " + moment(this.state.dt_inicioQuestFormando).format("DD/MM/YYYY") : ""],
            [this.state.dt_fimQuestFormando ? "Data fim: " + moment(this.state.dt_fimQuestFormando).format("DD/MM/YYYY") : ""],
            ["Curso", "Nº Formando", "Nome", "Questão", "Resposta", "Data/Hora"]
        ];

        return questoes;
    };

    disabledDate = current => {
        if (this.state.filtro == 2 && this.state.visibleDisciplina)
            return current < moment(this.state.dt_inicio_limite).startOf("day") || current > moment(this.state.dt_fim_limite).endOf("day");
        else return false;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.listarLinguas();
            this.listarQuestionarios();
            if (document.getElementById("input-foto")) document.getElementById("input-foto").value = "";
        }
    };

    validarValor = (rule, value, callback) => {
        if (isNaN(value)) callback("O valor deve ser um número");

        callback();
    };

    validarLink = (rule, value, callback) => {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        if (!pattern.test(value) && value) callback("Introduza uma página válida");

        callback();
    };

    adicionarHorario = horario => {
        let horarios = this.state.horarios;
        horarios.push(horario);

        this.setState({
            horarios,
            visibleDrawerHorarios: false
        });
    };

    alterarHorario = horario => {
        const index = this.state.horarioIndex;
        let horarios = this.state.horarios;

        horarios[index].data = horario.data;
        horarios[index].hora_ini = horario.hora_ini;
        horarios[index].hora_fim = horario.hora_fim;
        horarios[index].sala = horario.sala;
        horarios[index].morada = horario.morada;
        horarios[index].localidade = horario.localidade;

        this.setState({
            horarios,
            horarioIndex: "",
            visibleDrawerHorarios: false
        });
    };

    detalheHorario = index => {
        this.setState({
            horarioIndex: index,
            detalheHorario: true,
            horario: this.state.horarios[index],
            visibleDrawerHorarios: true
        });
    };

    excluirHorario = index => {
        confirm({
            title: "Pretende excluir este horário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const horarios = this.state.horarios.slice();
                horarios.splice(index, 1);

                this.setState({
                    horarios
                });
            }
        });
    };

    render() {
        const Dragger = Upload.Dragger;
        const { TextArea } = Input;
        const {
            edicoes,
            categorias,
            categoriaAtual,
            titulo_pagina,
            //FILTROS
            filtro,
            filtroEdicao,
            filtroCategoria,
            //TABELA
            loading_table,
            cursos,
            cursosMobile,
            //PAGINATION
            pagination,
            //INFO PRINCIPAL
            cursoid,
            disciplinaid,
            preview,
            titulo,
            breveDescricao,
            categoria,
            descricao,
            certificacao,
            portaria,
            componente_formacao,
            codigo,
            infos,
            questionario_ag,
            questionario_ac,
            questionario_ai,
            questionario_ig,
            questionario_ic,
            questionario_ia,
            dt_inicio,
            dt_fim,
            dt_disponivel,
            dt_fecho_inscricoes,
            precedencia,
            obrigatorio,
            precedencias,
            carga_horaria,
            editavel_formadores,
            fechado,
            editavel_gestao,
            curso_visivel,
            conteudos_visiveis,
            inscricao,
            tipo_inscricao,
            visivel_inscritos,
            esconderListaParticipantes,
            habilitarCompetencias,
            habilitarGamificacao,
            valorCurso,
            valorCertificado,
            subscricao,
            tipologia,
            horarios,
            horario,
            horarioIndex,
            dedicacao,
            paginaExterna,
            edicao,
            ano,
            turma,
            nome_projeto,
            imagem_projeto,
            //DRAWER
            visible,
            visibleDisciplina,
            procurar,
            iconLoading,
            //SELECTS
            linguas,
            listaLinguas,
            listaTranscricaoLinguas,
            listaTipos,
            listaEdicoes,
            listaCategorias,
            listaQuestionariosAG,
            listaQuestionariosAC,
            listaQuestionariosAI,
            listaQuestionariosIG,
            listaQuestionariosIC,
            listaQuestionariosIA,
            listaProcurarCoordenadores,
            listaCoordenadores,
            listaProcurarFormadores,
            listaFormadores,
            listaProcurarPrecedencia,
            listaPrecedencia,
            //UPLOAD IMAGEM VALIDATE
            validateStatus,
            help,
            //DRAWER INFORMACOES
            index,
            dataInfo,
            descricaoInfo,
            detalhe,
            visibleInformacoes,
            //VALIDAÇÔES INFO CURSO
            info_status,
            info_help,
            //UPLOAD
            fichaCurso,
            fichaImagemProjeto,
            fichaInscricao,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL DUPLICAR
            visibleDuplicar,
            textoModal,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheCategoria,
            detalhePeriodo,
            detalheEstado,
            detalheInscritos,
            //DRAWER HORARIOS,
            visibleDrawerHorarios,
            detalheHorario,
            isGeral,
            isPorHora
        } = this.state;

        const { getFieldDecorator } = this.props.form;

        const columnsCursos = [
            {
                title: "Edição",
                dataIndex: "edicao",
                className: "td-150"
            },
            {
                title: "Curso",
                dataIndex: "curso",
                //className: "td-curso td-265"
                className: "td-curso"
            },
            {
                title: "Categoria",
                dataIndex: "categoria",
                className: "td-155"
            },
            {
                title: "Periodo",
                dataIndex: "periodo",
                className: "td-300"
            },
            {
                title: "Inscritos",
                dataIndex: "inscritos"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsCursosMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsCursosComDisciplinas = [
            {
                title: "Edição",
                dataIndex: "edicao",
                className: "td-150"
            },
            {
                title: "Curso",
                dataIndex: "curso",
                //className: "td-curso td-265"
                className: "td-curso"
            },
            {
                title: "Categoria",
                dataIndex: "categoria",
                className: "td-155"
            },
            {
                title: "Periodo",
                dataIndex: "periodo",
                className: "td-300"
            },
            {
                title: "Inscritos",
                dataIndex: "inscritos",
                className: "td-165"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsCursosComDisciplinasMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-opcoes-lvl1"
            }
        ];

        const columnsDisciplinas = [
            {
                title: "Edição",
                dataIndex: "edicao",
                className: "td-150"
            },
            {
                title: "Disciplina",
                dataIndex: "curso",
                //className: "td-curso td-250",
                className: "td-curso",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "categoria",
                className: "td-160",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "periodo",
                className: "td-295",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "inscritos",
                className: "td-160",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsDisciplinasMobile = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                className: "td-curso td-250",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const props = {
            accept: ".pdf",
            multiple: false,
            onRemove: file => {
                this.setState({
                    fichaCurso: []
                });
                //this.setState(state => {
                //    const index = state.fichaCurso.indexOf(file);
                //    const newFileList = state.fichaCurso.slice();
                //    newFileList.splice(index, 1);

                //    return {
                //        fichaCurso: newFileList,
                //        fichaCursoEliminado: true
                //    };
                //});
            },
            defaultFileList: fichaCurso,
            beforeUpload: file => {
                //if (this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                //    this.setState(state => ({
                //        fichaCurso: [...state.fichaCurso, file],
                //        fichaCursoEliminado: false
                //    }));
                //} else openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");

                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload");
                    return false;
                }

                this.setState(state => ({
                    fichaCurso: [file]
                }));

                return false;
            },
            fichaCurso
        };

        const propsImagem = {
            accept: ".png",
            multiple: false,
            onRemove: file => {
                this.setState({
                    fichaImagemProjeto: []
                });
                //this.setState(state => {
                //    const index = state.fichaCurso.indexOf(file);
                //    const newFileList = state.fichaCurso.slice();
                //    newFileList.splice(index, 1);

                //    return {
                //        fichaCurso: newFileList,
                //        fichaCursoEliminado: true
                //    };
                //});
            },
            defaultFileList: fichaImagemProjeto,
            beforeUpload: file => {
                //if (this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                //    this.setState(state => ({
                //        fichaCurso: [...state.fichaCurso, file],
                //        fichaCursoEliminado: false
                //    }));
                //} else openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");

                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload");
                    return false;
                }

                console.log("AAA: ", file);

                this.setState(state => ({
                    fichaImagemProjeto: [file]
                }));

                return false;
            },
            fichaImagemProjeto
        };

        const propsInscricao = {
            accept: ".pdf",
            multiple: false,
            onRemove: file => {
                this.setState({
                    fichaInscricao: []
                });
                //this.setState(state => {
                //    const index = state.fichaInscricao.indexOf(file);
                //    const newFileList = state.fichaInscricao.slice();
                //    newFileList.splice(index, 1);

                //    return {
                //        fichaInscricao: newFileList,
                //        fichaInscricaoEliminado: true
                //    };
                //});
            },
            defaultFileList: fichaInscricao,
            beforeUpload: file => {
                //if (this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                //    this.setState(state => ({
                //        fichaInscricao: [...state.fichaInscricao, file],
                //        fichaInscricaoEliminado: false
                //    }));
                //} else openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");

                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload");
                    return false;
                }

                this.setState(state => ({
                    fichaInscricao: [file]
                }));

                return false;
            },
            fichaInscricao
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo={titulo_pagina} />
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <>
                                <div className="filtros filtros-desktop" style={{ display: "block" }}>
                                    <div className="bloco-filtros-1">
                                        <BotaoProcurar
                                            tem_placeholder
                                            placeholder="Procurar por Curso"
                                            HandleKeyPress={this.HandleKeyPress}
                                            resetCaixaProcura={this.resetCaixaProcura}
                                        />
                                        <Switch
                                            checkedChildren="Ativos"
                                            unCheckedChildren="Inativos"
                                            defaultChecked
                                            onChange={this.handleChangeSwitch}
                                        />
                                    </div>
                                    <br />
                                    <div className="bloco-filtros-2">
                                        <Select
                                            value={filtroEdicao}
                                            className="filtro-selects"
                                            onChange={this.handleChangeFiltroEdicoes}
                                            placeholder="Edição"
                                            disabled={!edicoes.length}
                                            allowClear={true}
                                        >
                                            {edicoes.map(edicao => (
                                                <Option value={edicao.id} key={edicao.id}>
                                                    {edicao.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Select
                                            value={filtroCategoria}
                                            className="filtro-selects"
                                            onChange={this.handleChangeFiltroCategorias}
                                            placeholder="Categoria"
                                            disabled={!categorias.length}
                                            allowClear={true}
                                        >
                                            {categorias.map(categoria => (
                                                <Option value={categoria.id} key={categoria.id}>
                                                    {categoria.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                        {this.context.disciplinas_entidade ? (
                                            <Select defaultValue={1} className="filtro-selects" onChange={this.handleChangeFiltro}>
                                                <Option value={1}>Cursos</Option>
                                                <Option value={2}>Cursos com disciplinas</Option>
                                            </Select>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="filtros filtros-mobile">
                                    <div className="bloco-esquerda bloco-esquerda-cursos">
                                        <div className="bloco-procurar">
                                            <BotaoProcurar
                                                tem_placeholder
                                                placeholder="Procurar por Curso"
                                                HandleKeyPress={this.HandleKeyPress}
                                                resetCaixaProcura={this.resetCaixaProcura}
                                            />
                                        </div>
                                        <div className="bloco-filtros">
                                            <Select
                                                value={filtroEdicao}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroEdicoes}
                                                placeholder="Selecionar Categoria"
                                                disabled={!edicoes.length}
                                                allowClear={true}
                                            >
                                                {edicoes.map(edicao => (
                                                    <Option value={edicao.id} key={edicao.id}>
                                                        {edicao.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Select
                                                value={filtroCategoria}
                                                className="filtro-selects"
                                                onChange={this.handleChangeFiltroCategorias}
                                                placeholder="Categoria"
                                                disabled={!categorias.length}
                                                allowClear={true}
                                            >
                                                {categorias.map(categoria => (
                                                    <Option value={categoria.id} key={categoria.id}>
                                                        {categoria.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                            {this.context.disciplinas_entidade ? (
                                                <Select defaultValue={1} className="filtro-selects" onChange={this.handleChangeFiltro}>
                                                    <Option value={1}>Cursos</Option>
                                                    <Option value={2}>Cursos com disciplinas</Option>
                                                </Select>
                                            ) : null}
                                        </div>
                                    </div>
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        onChange={this.handleChangeSwitch}
                                    />
                                </div>
                            </>
                            {filtro === 2 ? (
                                <>
                                    <Table
                                        id="cursos-disciplinas"
                                        className="curso-disciplinas-desktop"
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columnsCursosComDisciplinas}
                                        dataSource={cursos}
                                        pagination={pagination}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onChange={this.handleChangeTable}
                                        expandedRowRender={(record, index) => (
                                            <Table
                                                id="disciplinas"
                                                columns={columnsDisciplinas}
                                                dataSource={this.listarSubCursos(record.subcursos, index, record)}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem registos!" }}
                                            />
                                        )}
                                    />
                                    <Table
                                        id="cursos-disciplinas"
                                        className="curso-disciplinas-mobile"
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columnsCursosComDisciplinasMobile}
                                        dataSource={cursosMobile}
                                        pagination={pagination}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onChange={this.handleChangeTable}
                                        expandedRowRender={(record, index) => (
                                            <Table
                                                id="disciplinas"
                                                columns={columnsDisciplinasMobile}
                                                dataSource={this.listarSubCursosMobile(record.subcursos, index, record)}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem registos!" }}
                                            />
                                        )}
                                    />
                                </>
                            ) : (
                                <>
                                    <Table
                                        id="tabela-cursos"
                                        className="tabela-cursos-desktop"
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columnsCursos}
                                        dataSource={cursos}
                                        pagination={pagination}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onChange={this.handleChangeTable}
                                    />
                                    <Table
                                        id="tabela-cursos"
                                        className="tabela-cursos-mobile"
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columnsCursosMobile}
                                        dataSource={cursosMobile}
                                        pagination={pagination}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onChange={this.handleChangeTable}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.showDrawer}>
                        +
                    </Link>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    title={
                        (cursoid > 0 && visible) || (disciplinaid > 0 && visibleDisciplina)
                            ? visible
                                ? "Alterar curso"
                                : "Alterar disciplina"
                            : visible
                            ? "Adicionar novo curso"
                            : "Adicionar nova disciplina"
                    }
                    width={720}
                    onClose={this.onClose}
                    visible={visible || visibleDisciplina}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-img">
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} alt="Logo do curso" />
                                </div>
                                <input
                                    type="file"
                                    id="input-foto"
                                    accept="image/*"
                                    onChange={this.uploadFoto}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            <span>Adicionar</span>
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <p>Tamanho máximo: 100KB</p>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            <span>Alterar</span>
                                        </Link>
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <p>Tamanho máximo: 100KB</p>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                        </Tooltip>
                                        <Link to="#" className="link-principal-border" onClick={this.removerFoto}>
                                            <span>Remover</span>
                                        </Link>
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <Form className="form-add-curso" layout="horizontal">
                            <Collapse defaultActiveKey={["1"]} className="collapse-campos">
                                <Panel header="Dados gerais" key="1">
                                    <Form.Item label="Nome">
                                        {getFieldDecorator("titulo", {
                                            initialValue: titulo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name="titulo" onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item label="Código">
                                        {getFieldDecorator("codigo", {
                                            initialValue: codigo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name="codigo" onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item label="Carga Horária">
                                        {getFieldDecorator("carga_horaria", {
                                            initialValue: carga_horaria,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<InputNumber name="carga_horaria" min={1} onChange={this.handleChangeCarga} />)}
                                    </Form.Item>
                                    <Form.Item label="Breve descrição">
                                        {getFieldDecorator("breveDescricao", {
                                            initialValue: breveDescricao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <TextArea
                                                maxLength="200"
                                                name="breveDescricao"
                                                onChange={this.handleChange}
                                                rows={4}
                                                style={{ height: "100%" }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Descrição detalhada / objetivos">
                                        {getFieldDecorator("descricao", {
                                            initialValue: descricao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<TextArea name="descricao" onChange={this.handleChange} rows={10} style={{ height: "100%" }} />)}
                                    </Form.Item>
                                    <Form.Item label="Edição">
                                        {getFieldDecorator("edicao", {
                                            initialValue: edicao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={edicao => this.setState({ edicao })}
                                            >
                                                {listaEdicoes.map((edicao, index) => (
                                                    <Option key={index} value={edicao.id}>
                                                        {edicao.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Categoria">
                                        {getFieldDecorator("categoria", {
                                            initialValue: categoria,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select placeholder="Selecionar" allowClear={true} onChange={this.handlerChangeSelectCategoria}>
                                                {listaCategorias.map((categoria, index) => (
                                                    <Option key={"categoria" + index} value={categoria.id}>
                                                        {categoria.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                        {categoria !== categoriaAtual &&
                                            ((cursoid > 0 && visible) || (disciplinaid > 0 && visibleDisciplina)) && (
                                                <div className="bloco-opcao-descricao">
                                                    <Alert
                                                        message="Verifique se as competências atribuídas nas atividades do curso estão na categoria definida para o curso"
                                                        description=""
                                                        type="info"
                                                        showIcon
                                                    />
                                                </div>
                                            )}
                                    </Form.Item>
                                    <Form.Item label="Idiomas das atividades">
                                        {getFieldDecorator("idioma", {
                                            initialValue: listaLinguas,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                mode="multiple"
                                                labelInValue
                                                placeholder="Selecionar"
                                                filterOption={false}
                                                onChange={value =>
                                                    this.setState({
                                                        idiomas: value.map(idioma => {
                                                            return idioma.key;
                                                        })
                                                    })
                                                }
                                                style={{ width: "100%" }}
                                            >
                                                {linguas.map((lingua, index) => (
                                                    <Option key={index} value={lingua.id}>
                                                        {lingua.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Certificação">
                                        {getFieldDecorator("certificacao", {
                                            initialValue: certificacao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                name="certificacao"
                                                allowClear={true}
                                                onChange={value => this.setState({ certificacao: value })}
                                            >
                                                <Option value="NAO_APLICAVEL">Não aplicável</Option>
                                                {/*<Option value="DIPLOMA">Diploma de conclusão</Option>
                                        <Option value="CERTIFICADO">Certificado detalhado</Option>*/}
                                                <Option value="MANUAL">Manual</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {certificacao == "CERTIFICADO" ? (
                                        <>
                                            <Form.Item label="Portaria">
                                                {getFieldDecorator("portaria", {
                                                    initialValue: portaria,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ]
                                                })(<Input name="portaria" onChange={this.handleChange} />)}
                                            </Form.Item>
                                            <Form.Item label="Componente de formação">
                                                {getFieldDecorator("componente_formacao", {
                                                    initialValue: componente_formacao,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ]
                                                })(<Input name="componente_formacao" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </>
                                    ) : null}
                                </Panel>
                                <Panel
                                    header={
                                        <>
                                            Informações
                                            <button
                                                className="botao-principal"
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    this.showDrawerInformacoes();
                                                }}
                                            >
                                                <Icon type="plus" />
                                            </button>
                                        </>
                                    }
                                    key="2"
                                >
                                    <Form.Item>
                                        {!infos.length ? <span>Sem registos</span> : null}
                                        <ul className="lista-informacoes-curso">
                                            {infos.map((info, index) => (
                                                <li key={"infos" + index}>
                                                    <div className="info-informacoes">
                                                        <span className="data-info">{moment(info.data).format("DD/MM/YYYY")}</span>
                                                        <span className="resumo-info">
                                                            {info.descricao.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                                                        </span>
                                                    </div>
                                                    <div className="informacoes-controlos">
                                                        <Link
                                                            to="#"
                                                            className="botao-icon-editar"
                                                            title="Descarregar"
                                                            onClick={() => this.detalheInfo(index)}
                                                        >
                                                            <Icon type="edit" />
                                                        </Link>
                                                        <Link
                                                            to="#"
                                                            className="botao-icon-excluir"
                                                            title="Excluir"
                                                            onClick={() => this.excluirInfo(index)}
                                                        >
                                                            <Icon type="delete" />
                                                        </Link>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </Form.Item>
                                </Panel>
                                <Panel header="Coordenadores / Instrutores" key="3">
                                    <Form.Item label="Coordenador(es)">
                                        {getFieldDecorator("coordenadores", {
                                            initialValue: listaProcurarCoordenadores,
                                            rules: [
                                                {
                                                    required: filtro == 2 && !visibleDisciplina,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                mode="multiple"
                                                labelInValue
                                                style={{ width: "100%" }}
                                                placeholder="Introduza os primeiros 4 carateres do nome do instrutor para selecionar..."
                                                filterOption={false}
                                                notFoundContent={procurar ? <Spin size="small" /> : null}
                                                onSearch={this.procurarCoordenadores}
                                                onChange={this.checkCoordenadores}
                                            >
                                                {listaCoordenadores.map((coordenador, index) => (
                                                    <Option key={coordenador.id}>{coordenador.nome_completo}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {(filtro == 1 || (filtro == 2 && visibleDisciplina)) && (
                                        <Form.Item label="Instrutor(es)">
                                            {getFieldDecorator("formadores", {
                                                initialValue: listaProcurarFormadores,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    labelInValue
                                                    style={{ width: "100%" }}
                                                    placeholder="Introduza os primeiros 4 carateres do nome do instrutor para selecionar..."
                                                    filterOption={false}
                                                    notFoundContent={procurar ? <Spin size="small" /> : null}
                                                    onSearch={this.procurarFormadores}
                                                    onChange={this.checkFormadores}
                                                >
                                                    {listaFormadores.map((formador, index) => (
                                                        <Option key={formador.id}>{formador.nome_completo}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    )}
                                </Panel>
                                <Panel header="Datas" key="5">
                                    <Form.Item
                                        label={
                                            <>
                                                Inicio{" "}
                                                <Tooltip
                                                    className="info-icon"
                                                    title="O curso só poderá ser acedido após a data do seu inicio"
                                                >
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        {getFieldDecorator("inicioCurso", {
                                            initialValue: dt_inicio,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <DatePicker
                                                //className="input-25"
                                                name="dt_inicio"
                                                format="DD-MM-YYYY"
                                                disabledDate={this.disabledDate}
                                                onChange={this.handleChangeInicio}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Término">
                                        {getFieldDecorator("fimCurso", {
                                            initialValue: dt_fim
                                        })(
                                            <DatePicker
                                                //className="input-25"
                                                name="dt_fim"
                                                format="DD-MM-YYYY"
                                                disabledDate={this.disabledDate}
                                                onChange={this.handleChangeFim}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                Disponibilização{" "}
                                                <Tooltip
                                                    className="info-icon"
                                                    title="O curso só ficará visível após a data de disponibilização"
                                                >
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        {getFieldDecorator("disCurso", {
                                            initialValue: dt_disponivel,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <DatePicker
                                                //className="input-25"
                                                name="dt_disponivel"
                                                format="DD-MM-YYYY"
                                                disabledDate={this.disabledDate}
                                                onChange={this.handleChangeDis}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Fecho das inscrições">
                                        <DatePicker
                                            //className="input-25"
                                            name="dt_fecho_inscricoes"
                                            value={dt_fecho_inscricoes}
                                            format="DD-MM-YYYY"
                                            disabledDate={this.disabledDate}
                                            onChange={this.handleChangeFechoInscricoes}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Configurações" key="6">
                                    <Form.Item>
                                        <Checkbox
                                            name="editavel_formadores"
                                            checked={editavel_formadores}
                                            onChange={this.handlerChangeCheck}
                                        >
                                            Editável pelos coordenadores e instrutores
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Checkbox name="editavel_gestao" checked={editavel_gestao} onChange={this.handlerChangeCheck}>
                                            Editável pelos gestores
                                        </Checkbox>
                                    </Form.Item>
                                    {this.context.visivel_entidade && (
                                        <Form.Item>
                                            <Checkbox name="curso_visivel" checked={curso_visivel} onChange={this.handlerChangeCheck}>
                                                Mostrar este curso na página pública do Canal{" "}
                                                <Tooltip
                                                    className="info-icon"
                                                    title="Este curso será visualizado por todos os utilizadores, incluindo os não autenticados"
                                                >
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </Checkbox>
                                        </Form.Item>
                                    )}
                                    {curso_visivel && (
                                        <Form.Item>
                                            <Checkbox
                                                name="conteudos_visiveis"
                                                checked={conteudos_visiveis}
                                                onChange={this.handlerChangeCheck}
                                            >
                                                {filtro === 2 && visible
                                                    ? "Mostrar as disciplinas do curso na página pública"
                                                    : "Mostrar as atividades do curso na página pública"}
                                            </Checkbox>
                                        </Form.Item>
                                    )}
                                    {(filtro == 1 || (filtro == 2 && visibleDisciplina)) && (
                                        <>
                                            <Form.Item>
                                                <Checkbox
                                                    name="esconderListaParticipantes"
                                                    checked={esconderListaParticipantes}
                                                    onChange={this.handlerChangeCheck}
                                                >
                                                    Lista de participantes só para os instrutores
                                                    <Tooltip
                                                        className="info-icon"
                                                        title="Apenas os instrutores visualizam lista de participantes; estes não conhecem quem são os outros participantes no curso, não têm acesso ao grupo com todos os participantes na conversação e não podem partilhar documentos com esse grupo; apenas conhecem outros participantes através do fórum"
                                                    >
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Form.Item>
                                            <Form.Item>
                                                <Checkbox
                                                    name="habilitarCompetencias"
                                                    checked={habilitarCompetencias}
                                                    onChange={this.handlerChangeCheck}
                                                >
                                                    Habilitar Avaliação por Competências
                                                    <Tooltip
                                                        className="info-icon"
                                                        title="O instrutor pode associar 1 ou mais competências, da lista de competências da mesma categoria do curso, às atividades do curso"
                                                    >
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Form.Item>
                                            {this.context.gamificacao_entidade && (
                                                <Form.Item>
                                                    <Checkbox
                                                        name="habilitarGamificacao"
                                                        checked={habilitarGamificacao}
                                                        onChange={this.handlerChangeCheck}
                                                    >
                                                        Habilitar Gamificação
                                                    </Checkbox>
                                                </Form.Item>
                                            )}
                                        </>
                                    )}
                                    <Form.Item label="Idiomas usados nas transcrições">
                                        {getFieldDecorator("transcricao", {
                                            initialValue: listaTranscricaoLinguas
                                        })(
                                            <Select
                                                mode="multiple"
                                                labelInValue
                                                placeholder="Selecionar"
                                                filterOption={false}
                                                onChange={value =>
                                                    this.setState({
                                                        idiomasTrascricao: value.map(idioma => {
                                                            return idioma.key;
                                                        })
                                                    })
                                                }
                                                style={{ width: "100%" }}
                                            >
                                                {linguas.map((lingua, index) => (
                                                    <Option key={index} value={lingua.id}>
                                                        {lingua.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Precedência">
                                        {getFieldDecorator("precedenciaModulo", {
                                            initialValue: listaProcurarPrecedencia
                                        })(
                                            <Select
                                                showSearch
                                                labelInValue
                                                style={{ width: "100%" }}
                                                placeholder="Introduza os primeiros 4 carateres do curso para selecionar..."
                                                filterOption={false}
                                                allowClear={true}
                                                notFoundContent={procurar ? <Spin size="small" /> : null}
                                                onSearch={
                                                    visible
                                                        ? value => this.procurarPrecedencia(value)
                                                        : value => this.procurarPrecedenciaDisciplinas(value)
                                                }
                                                onChange={this.handlerChangeSelectPrecedencia}
                                            >
                                                {listaPrecedencia.map((curso, index) => (
                                                    <Option key={curso.id}>{curso.nome}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {precedencia ? (
                                        <Form.Item>
                                            <Checkbox name="obrigatorio" checked={obrigatorio} onChange={this.handlerChangeCheck}>
                                                Precedência obrigatória{" "}
                                                <Tooltip
                                                    className="info-icon"
                                                    title="Um curso com precedência obrigatória exige ao aluno a conclusão do curso indicado no campo anterior; se não for indicada a precedência como obrigatória o aluno recebe a sugestão de frequência do curso indicado"
                                                >
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </Checkbox>
                                        </Form.Item>
                                    ) : null}
                                    <Form.Item label="Ano">
                                        <Input name="ano" value={ano} onChange={this.handleChange} />
                                    </Form.Item>
                                    <Form.Item label="Turma">
                                        <Input name="turma" value={turma} onChange={this.handleChange} />
                                    </Form.Item>
                                    <Form.Item label="Tipologia">
                                        {getFieldDecorator("tipologia", {
                                            initialValue: tipologia,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={tipologia => this.setState({ tipologia })}
                                            >
                                                <Option value="NAO_APLICAVEL">Não aplicável</Option>
                                                <Option value="ONLINE">Online</Option>
                                                <Option value="PRESENCIAL">Presencial</Option>
                                                <Option value="MISTO">Misto / b-Learning</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Dedicação">
                                        {getFieldDecorator("dedicacao", {
                                            initialValue: dedicacao,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={dedicacao => this.setState({ dedicacao })}
                                            >
                                                <Option value="NAO_APLICAVEL">Não aplicável</Option>
                                                <Option value="PARCIAL">Tempo parcial</Option>
                                                <Option value="INTEIRO">Tempo inteiro</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                Ficha do Curso (.pdf){""}
                                                <Tooltip className="info-icon" title="Documento / Folheto / Brochura">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <Dragger {...props} fileList={this.state.fichaCurso}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formato válido: .pdf">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    </Form.Item>
                                    <Form.Item label="Página web externa do curso">
                                        {getFieldDecorator("paginaExterna", {
                                            rules: [
                                                {
                                                    validator: this.validarLink
                                                }
                                            ],
                                            initialValue: paginaExterna
                                        })(<Input name="paginaExterna" onChange={this.handleChange} />)}
                                    </Form.Item>
                                </Panel>
                                {((filtro == 2 && visibleDisciplina) || filtro != 2) && (
                                    <Panel header="Inscrição" key="7">
                                        <Form.Item label="Tipo">
                                            {getFieldDecorator("tipo_inscricao", {
                                                initialValue: tipo_inscricao,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(
                                                <Select
                                                    placeholder="Selecionar"
                                                    onChange={tipo_inscricao => this.setState({ tipo_inscricao })}
                                                >
                                                    <Option value="MANUAL">Manual</Option>
                                                    <Option value="DIGITAL">Digital</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        {tipo_inscricao === "MANUAL" ? (
                                            <>
                                                <Form.Item>
                                                    <Checkbox name="inscricao" checked={inscricao} onChange={this.handlerChangeCheck}>
                                                        Este curso exige inscrição prévia para poder ser frequentado{" "}
                                                        <Tooltip
                                                            className="info-icon"
                                                            title="Apenas participantes inscritos terão acesso às suas atividades"
                                                        >
                                                            <Icon type="question-circle-o" />
                                                        </Tooltip>
                                                    </Checkbox>
                                                </Form.Item>
                                                {inscricao && (
                                                    <Form.Item>
                                                        <Checkbox
                                                            name="visivel_inscritos"
                                                            checked={visivel_inscritos}
                                                            onChange={this.handlerChangeCheck}
                                                        >
                                                            O curso apenas será visível pelos participantes inscritos{" "}
                                                            <Tooltip
                                                                className="info-icon"
                                                                title="Um curso com inscrição obrigatória é apenas visível pelos seus alunos inscritos; o curso pode ficar visível na página pública de acordo com a parametrização acima 'Mostrar este curso na página pública do Canal'"
                                                            >
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </Checkbox>
                                                    </Form.Item>
                                                )}
                                                <Form.Item
                                                    label={
                                                        <>
                                                            Ficha de Inscrição (.pdf){""}{" "}
                                                            <Tooltip
                                                                className="info-icon"
                                                                title="O documento disponibilizado aqui deve ser descarregado, preenchido, assinado (se solicitado) e carregado pelo aluno na sua inscrição neste curso."
                                                            >
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </>
                                                    }
                                                >
                                                    <Dragger {...propsInscricao} fileList={this.state.fichaInscricao}>
                                                        <p className="ant-upload-drag-icon">
                                                            <i className="fas fa-upload" />
                                                        </p>
                                                        <p className="ant-upload-text">
                                                            Adicionar anexo{" "}
                                                            <Tooltip className="info-icon" title="Formato válido: .pdf">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </p>
                                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                    </Dragger>
                                                </Form.Item>
                                            </>
                                        ) : null}
                                        {tipo_inscricao === "DIGITAL" ? (
                                            <Form.Item>
                                                <label className="label-com-botao">
                                                    Horários
                                                    <button
                                                        className="botao-principal"
                                                        onClick={() =>
                                                            this.setState({ visibleDrawerHorarios: true, detalheHorario: false })
                                                        }
                                                    >
                                                        <Icon type="plus" />
                                                    </button>
                                                </label>
                                                <div className="lista-horarios">
                                                    {!horarios.length ? <span>Sem registos</span> : null}
                                                    {horarios.map((horario, index) => (
                                                        <div key={index} className="horario">
                                                            <div>
                                                                <div className="info-data">
                                                                    <span>{moment(horario.data).format("DD/MM/YYYY")}</span>
                                                                    <span style={{ marginLeft: 10 }}>
                                                                        {horario.hora_ini} - {horario.hora_fim}
                                                                    </span>
                                                                </div>
                                                                <div className="info">
                                                                    <span className="sala">{horario.sala}</span>
                                                                    <span className="morada">
                                                                        {horario.morada}, {horario.localidade}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="horarios-opcoes">
                                                                <Dropdown overlay={this.montarMenuHorario(index)} placement="bottomLeft">
                                                                    <Link to="#" className="botao-icon-configs">
                                                                        <i className="fas fa-cog" />
                                                                    </Link>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Form.Item>
                                        ) : null}
                                        {!this.context.monetizacao_entidade ? (
                                            <>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            Valor do Curso
                                                            <Tooltip
                                                                className="info-icon"
                                                                title="Informativo, sem método de pagamento associado"
                                                            >
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </>
                                                    }
                                                >
                                                    {getFieldDecorator("valorCurso", {
                                                        rules: [
                                                            {
                                                                validator: this.validarValor
                                                            }
                                                        ],
                                                        initialValue: valorCurso
                                                    })(
                                                        <Input
                                                            className="input-50"
                                                            name="valorCurso"
                                                            onChange={this.handleChange}
                                                            prefix={this.context.moeda_entidade}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                    </Panel>
                                )}
                                <Panel header="Questionários de Satisfação" key="4">
                                    <Form.Item label="Aluno sobre o Geral">
                                        {getFieldDecorator("questionario_ag", {
                                            initialValue: questionario_ag
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={questionario_ag => this.setState({ questionario_ag })}
                                            >
                                                {listaQuestionariosAG.map((questionario, index) => (
                                                    <Option key={index} value={questionario.id}>
                                                        {questionario.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Aluno sobre o Curso">
                                        {getFieldDecorator("questionario_ac", {
                                            initialValue: questionario_ac
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={questionario_ac => this.setState({ questionario_ac })}
                                            >
                                                {listaQuestionariosAC.map((questionario, index) => (
                                                    <Option key={index} value={questionario.id}>
                                                        {questionario.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Aluno sobre os Instrutores">
                                        {getFieldDecorator("questionario_ai", {
                                            initialValue: questionario_ai
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={questionario_ai => this.setState({ questionario_ai })}
                                            >
                                                {listaQuestionariosAI.map((questionario, index) => (
                                                    <Option key={index} value={questionario.id}>
                                                        {questionario.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Instrutor sobre o Geral">
                                        {getFieldDecorator("questionario_ig", {
                                            initialValue: questionario_ig
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={questionario_ig => this.setState({ questionario_ig })}
                                            >
                                                {listaQuestionariosIG.map((questionario, index) => (
                                                    <Option key={index} value={questionario.id}>
                                                        {questionario.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Instrutor sobre o Curso">
                                        {getFieldDecorator("questionario_ic", {
                                            initialValue: questionario_ic
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={questionario_ic => this.setState({ questionario_ic })}
                                            >
                                                {listaQuestionariosIC.map((questionario, index) => (
                                                    <Option key={index} value={questionario.id}>
                                                        {questionario.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Instrutor sobre os Participantes">
                                        {getFieldDecorator("questionario_ia", {
                                            initialValue: questionario_ia
                                        })(
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={questionario_ia => this.setState({ questionario_ia })}
                                            >
                                                {listaQuestionariosIA.map((questionario, index) => (
                                                    <Option key={index} value={questionario.id}>
                                                        {questionario.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Panel>
                                {this.context.monetizacao_entidade &&
                                    (this.context.vendaCursos || this.context.vendaCertificados || this.context.subscricao_entidade) && (
                                        <Panel header="Monetização" key="8">
                                            <>
                                                {this.context.subscricao_entidade ? (
                                                    <Form.Item>
                                                        <Checkbox name="subscricao" checked={subscricao} onChange={this.handlerChangeCheck}>
                                                            Curso com subscrição{" "}
                                                            <Tooltip
                                                                className="info-icon"
                                                                title="O curso fica apenas disponível para subscritores"
                                                            >
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </Checkbox>
                                                    </Form.Item>
                                                ) : null}
                                                {this.context.vendaCursos && (
                                                    <Form.Item
                                                        label={
                                                            subscricao
                                                                ? "Valor do Curso para o utilizador que não tem subscrição"
                                                                : "Valor do Curso"
                                                        }
                                                    >
                                                        {getFieldDecorator("valorCurso", {
                                                            rules: [
                                                                {
                                                                    validator: this.validarValor
                                                                }
                                                            ],
                                                            initialValue: valorCurso
                                                        })(
                                                            <Input
                                                                className="input-50"
                                                                name="valorCurso"
                                                                onChange={this.handleChange}
                                                                prefix={this.context.moeda_entidade}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                )}
                                                {this.context.vendaCertificados && (
                                                    <Form.Item label="Valor do Certificado">
                                                        {getFieldDecorator("valorCertificado", {
                                                            rules: [
                                                                {
                                                                    validator: this.validarValor
                                                                }
                                                            ],
                                                            initialValue: valorCertificado
                                                        })(
                                                            <InputNumber
                                                                className="input-50"
                                                                name="valorCertificado"
                                                                onChange={this.handleChangeValorCertificado}
                                                                formatter={value =>
                                                                    `${this.context.moeda_entidade} ${value}`.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ","
                                                                    )
                                                                }
                                                                parser={value => value.replace(/\$\s?|(,*)/g, "").replace("R", "")}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                )}
                                            </>
                                        </Panel>
                                    )}

                                <Panel header="Projetos" key="9">
                                    <Form.Item label="Projeto">
                                        <Input name="nome_projeto" value={nome_projeto} onChange={this.handleChange} />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <>
                                                Imagem Projeto (.png){""}
                                                <Tooltip className="info-icon" title="Documento / Folheto / Brochura">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <Dragger {...propsImagem} fileList={this.state.fichaImagemProjeto}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formato válido: .png">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    </Form.Item>

                                    {/*<Form.Item>*/}
                                    {/*    <Checkbox name="presenca" checked={isGeral} onChange={this.handlerChangeCheckboxGeral}>*/}
                                    {/*        Sumário Geral*/}
                                    {/*    </Checkbox>*/}
                                    {/*    <Checkbox name="falta" checked={isPorHora} onChange={this.handlerChangeCheckboxPorHora}>*/}
                                    {/*        Sumário por Hora*/}
                                    {/*    </Checkbox>*/}
                                    {/*</Form.Item>*/}
                                </Panel>
                            </Collapse>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {(cursoid > 0 && visible) || (disciplinaid > 0 && visibleDisciplina) ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.guardarCurso} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button
                                className="botao-principal"
                                disabled={iconLoading}
                                onClick={visible ? e => this.criarCurso(e) : e => this.criarDisciplina(e)}
                                type="primary"
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                    <DrawerHorarios
                        detalheHorario={detalheHorario}
                        horarioIndex={horarioIndex}
                        horario={horario}
                        ultimoHorario={horarios[horarios.length - 1]}
                        adicionarHorario={this.adicionarHorario}
                        alterarHorario={this.alterarHorario}
                        visibleDrawerHorarios={visibleDrawerHorarios}
                        onClose={() => this.setState({ visibleDrawerHorarios: false })}
                    />
                    <DrawerInformacoes
                        index={index}
                        dataInfo={dataInfo}
                        descricaoInfo={descricaoInfo}
                        detalhe={detalhe}
                        visible={visibleInformacoes}
                        onClose={this.onCloseInformacoes}
                        handleChange={this.handleChange}
                        handleChangeData={this.handleChangeData}
                        adicionar={this.adicionarInfo}
                        editar={this.editarInfo}
                    />
                </Drawer>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
                <Modal visible={visibleDuplicar} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A {textoModal}...</p>
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Categoria">{detalheCategoria}</Form.Item>
                                <Form.Item label="Periodo">{detalhePeriodo}</Form.Item>
                                {detalheEstado ? <Form.Item label="Estado">{detalheEstado}</Form.Item> : null}
                                {detalheInscritos ? <Form.Item label="Inscritos">{detalheInscritos}</Form.Item> : null}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormCursos = Form.create({ name: "cursos" })(GestaoCursos);

export default FormCursos;
